import React, { useState, useEffect, useRef } from 'react';
import './RentalsPage.css';
const companyLogo = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';

function AdminRentalsPage() {
  const [rentals, setRentals] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  // Pour la modale de détails
  const [selectedRental, setSelectedRental] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  
  // Pour la modale de refus
  const [isRefusalModalOpen, setIsRefusalModalOpen] = useState(false);
  const [refusalMessage, setRefusalMessage] = useState('');
  
  // Pour la modale de devis
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  // Références pour l'impression du devis
  const quoteRef = useRef(null);

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  
  // Référence pour l'impression de la facture
  const invoiceRef = useRef(null);
  
  // Informations de l'entreprise
  const companyInfo = {
    name: "Ouest Pro Event",
    address: "4 Rue de L'argoat",
    zipCode: "22200",
    city: "Saint-Agathon",
    phone: "06.76.98.99.89",
    email: "contact@ouest-proevent.fr",
    siret: "123 456 789 00012",
    tvaRate: 20
  };
  
  // État pour la livraison
  const [deliveryDistance, setDeliveryDistance] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  
  // Fonction pour obtenir les actions disponibles selon le statut
  const getAvailableActions = (status) => {
    switch (status.toLowerCase()) {
      case 'en attente':
        return {
          canSendQuote: true,
          canRefuse: true,
          canSendInvoice: false
        };
      case 'devis envoyé par mail':
        return {
          canSendQuote: false,
          canRefuse: true,
          canSendInvoice: false
        };
      case 'paid':
        return {
          canSendQuote: false,
          canRefuse: false,
          canSendInvoice: true
        };
      default:
        return {
          canSendQuote: false,
          canRefuse: false,
          canSendInvoice: false
        };
    }
  };

  // Fonction pour calculer la distance et le prix de livraison avec OpenStreetMap
  const calculateDistanceAndPrice = async (rental) => {
    // Si ce n'est pas une livraison ou si la distance est déjà définie, ne rien faire
    if (rental.delivery_method !== 'delivery' || 
        (rental.delivery_distance && rental.delivery_distance > 0)) {
      return;
    }

    try {
      // 1. Géocodage de l'adresse de l'entreprise avec Nominatim
      const companyAddress = `${companyInfo.address}, ${companyInfo.zipCode} ${companyInfo.city}, France`;
      const companyResponse = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(companyAddress)}`);
      const companyData = await companyResponse.json();
      
      if (!companyData.length) {
        console.error("Adresse de l'entreprise introuvable");
        return null;
      }
      
      // 2. Géocodage de l'adresse de livraison
      const deliveryAddress = `${rental.delivery_street}, ${rental.delivery_zip_code} ${rental.delivery_city}, France`;
      const deliveryResponse = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(deliveryAddress)}`);
      const deliveryData = await deliveryResponse.json();
      
      if (!deliveryData.length) {
        console.error("Adresse de livraison introuvable");
        return null;
      }
      
      // 3. Récupérer les coordonnées
      const companyCoords = {
        lon: companyData[0].lon,
        lat: companyData[0].lat
      };
      
      const deliveryCoords = {
        lon: deliveryData[0].lon,
        lat: deliveryData[0].lat
      };
      
      // 4. Utiliser OSRM pour calculer l'itinéraire et la distance
      const osrmResponse = await fetch(
        `https://router.project-osrm.org/route/v1/driving/${companyCoords.lon},${companyCoords.lat};${deliveryCoords.lon},${deliveryCoords.lat}?overview=false`
      );
      
      const routeData = await osrmResponse.json();
      
      if (routeData.code !== 'Ok' || !routeData.routes.length) {
        console.error("Impossible de calculer l'itinéraire");
        return null;
      }
      
      // 5. Récupérer la distance en mètres et convertir en kilomètres
      const distanceInMeters = routeData.routes[0].distance;
      const distanceInKm = Math.ceil(distanceInMeters / 1000); // Arrondir au km supérieur
      
      // 6. Calculer le prix (1.50€ par km aller-retour)
      const pricePerKm = 1.50;
      const deliveryPrice = distanceInKm * 2 * pricePerKm; // × 2 pour l'aller-retour
      
      // Mettre à jour les états
      setDeliveryDistance(distanceInKm);
      setDeliveryPrice(deliveryPrice);
      
      // Mettre à jour les données du devis avec le nouveau prix total
      const totalHT = quoteData.subtotal + deliveryPrice + (quoteData.washingOption ? 20 : 0);
      const tvaAmount = totalHT * (companyInfo.tvaRate / 100);
      const totalTTC = totalHT + tvaAmount;
      
      setQuoteData({
        ...quoteData,
        deliveryPrice: deliveryPrice,
        totalPrice: totalTTC
      });
      
      return { distance: distanceInKm, price: deliveryPrice };
      
    } catch (error) {
      console.error("Erreur lors du calcul de la distance:", error);
      return null;
    }
  };

  const calculateDeliveryPrice = (distance) => {
    // Prix par kilomètre, multiplié par 2 pour l'aller-retour
    return distance * 2 * 2; // 2€ par km × 2 (aller-retour)
  };
  
  const handleDeliveryDistanceChange = (e) => {
    const distance = parseFloat(e.target.value);
    const newDeliveryPrice = calculateDeliveryPrice(distance);
    
    setDeliveryDistance(distance);
    setDeliveryPrice(newDeliveryPrice);
    
    // Mettre à jour le total avec le nouveau prix de livraison
    const totalHT = quoteData.subtotal + newDeliveryPrice + (quoteData.washingOption ? 20 : 0);
    const totalTVA = totalHT * (companyInfo.tvaRate / 100);
    const totalTTC = totalHT + totalTVA;
    
    setQuoteData({
      ...quoteData,
      deliveryPrice: newDeliveryPrice,
      totalPrice: totalTTC
    });
  };

  // Récupérer la liste des locations paginée
  const fetchRentals = async (pageNumber = 1) => {
    try {
      console.log(`https://backend.ouest-pro-event.fr/admin/rentals?page=${pageNumber}&per_page=15`)
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals?page=${pageNumber}&per_page=15`);
      const data = await res.json();
      setRentals(data.rentals);
      setTotalPages(data.pages);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des locations:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRentals(page);
  }, [page]);

  // Fonction pour ouvrir la modale de détails
  const openDetailsModal = (rental) => {
    setSelectedRental(rental);
    setIsDetailsModalOpen(true);
  };

  // Fonction pour ouvrir la modale de devis
  const openQuoteModal = (rental) => {
    // Calculer le nombre de jours de location (incluant la date de fin)
    const startDate = new Date(rental.start_date);
    const endDate = new Date(rental.end_date);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    
    // Définir la distance de livraison par défaut
    let initialDeliveryDistance = 0;
    let initialDeliveryPrice = 0;
    
    if (rental.delivery_distance && rental.delivery_distance > 0) {
      // Si la distance est déjà définie, l'utiliser
      initialDeliveryDistance = rental.delivery_distance;
      initialDeliveryPrice = rental.delivery_price || calculateDeliveryPrice(initialDeliveryDistance);
    }
    
    // Préparer les données du devis
    const subtotal = rental.items.reduce((sum, item) => 
      sum + (diffDays * item.quantity * item.daily_price), 0);

    // Vérifier si l'option de lavage est activée
    const washingOption = rental.washing_option || false;
    const washingPrice = washingOption ? 20 : 0;

    // Calculer le total HT avec livraison et option lavage
    const totalHT = subtotal + initialDeliveryPrice + washingPrice;

    // Calculer la TVA
    const tvaAmount = totalHT * (companyInfo.tvaRate / 100);

    // Calculer le total TTC
    const totalTTC = totalHT + tvaAmount;
    
    const quoteData = {
      id: rental.id,
      quoteNumber: `D-${rental.id}-${new Date().getFullYear()}`,
      clientName: `${rental.first_name} ${rental.last_name}`,
      email: rental.email,
      deliveryMethod: rental.delivery_method,
      deliveryAddress: rental.delivery_method === 'delivery' ? {
        street: rental.delivery_street,
        city: rental.delivery_city,
        zipCode: rental.delivery_zip_code
      } : null,
      startDate: rental.start_date,
      endDate: rental.end_date,
      items: rental.items.map(item => ({
        ...item,
        subtotal: diffDays * item.quantity * item.daily_price
      })),
      days: diffDays,
      subtotal: subtotal,
      deliveryPrice: initialDeliveryPrice,
      washingOption: washingOption,
      washingPrice: washingPrice,
      tvaAmount: tvaAmount,
      totalPrice: totalTTC
    };
    
    // Mettre à jour les états et ouvrir la modale
    setDeliveryDistance(initialDeliveryDistance);
    setDeliveryPrice(initialDeliveryPrice);
    setQuoteData(quoteData);
    setIsQuoteModalOpen(true);
    
    // Si c'est une livraison et que la distance n'est pas encore définie, la calculer
    if (rental.delivery_method === 'delivery' && 
        (!rental.delivery_distance || rental.delivery_distance <= 0)) {
      
      // Activer l'indicateur de chargement
      setLoading2(true);
      
      // Calculer la distance après un délai pour respecter les limites de l'API
      setTimeout(() => {
        calculateDistanceAndPrice(rental, quoteData).then(result => {
          // Désactiver l'indicateur de chargement
          setLoading2(false);
          
          if (result) {
            // Mise à jour des états avec les nouvelles valeurs
            setDeliveryDistance(result.distance);
            setDeliveryPrice(result.price);
            
            // Recalcul du total avec la nouvelle distance et l'option lavage
            const newTotalHT = subtotal + result.price + (washingOption ? 20 : 0);
            const newTvaAmount = newTotalHT * (companyInfo.tvaRate / 100);
            const newTotalTTC = newTotalHT + newTvaAmount;
            
            // Mise à jour du devis
            setQuoteData({
              ...quoteData,
              deliveryPrice: result.price,
              tvaAmount: newTvaAmount,
              totalPrice: newTotalTTC
            });
          } else {
            // Si le calcul a échoué, proposer de saisir manuellement
            alert("Le calcul automatique de la distance a échoué. Veuillez saisir manuellement la distance.");
            setIsEditMode(true);
          }
        });
      }, 1000); // Délai d'1 seconde
    }
  };

  // Fonction pour ouvrir la modale de facture
  const openInvoiceModal = (rental) => {
    // Calculer le nombre de jours de location (incluant la date de fin)
    const startDate = new Date(rental.start_date);
    const endDate = new Date(rental.end_date);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    
    // Définir la distance de livraison
    let deliveryDistance = rental.delivery_distance || 0;
    const deliveryPrice = rental.delivery_price || calculateDeliveryPrice(deliveryDistance);
    
    // Vérifier si l'option de lavage est activée
    const washingOption = rental.washing_option || false;
    const washingPrice = washingOption ? 20 : 0;

    // Préparer les données de la facture
    const subtotal = rental.items.reduce((sum, item) => 
      sum + (diffDays * item.quantity * item.daily_price), 0);
    
    // Calculer le total HT avec livraison et option lavage
    const totalHT = subtotal + deliveryPrice + washingPrice;
    
    // Calculer la TVA
    const tvaAmount = totalHT * (companyInfo.tvaRate / 100);
    
    // Calculer le total TTC
    const totalTTC = totalHT + tvaAmount;
    
    const invoiceData = {
      id: rental.id,
      invoiceNumber: `F-${rental.id}-${new Date().getFullYear()}`,
      clientName: `${rental.first_name} ${rental.last_name}`,
      email: rental.email,
      deliveryMethod: rental.delivery_method,
      deliveryAddress: rental.delivery_method === 'delivery' ? {
        street: rental.delivery_street,
        city: rental.delivery_city,
        zipCode: rental.delivery_zip_code
      } : null,
      startDate: rental.start_date,
      endDate: rental.end_date,
      items: rental.items.map(item => ({
        ...item,
        subtotal: diffDays * item.quantity * item.daily_price
      })),
      days: diffDays,
      subtotal: subtotal,
      deliveryPrice: deliveryPrice,
      deliveryDistance: deliveryDistance,
      washingOption: washingOption,
      washingPrice: washingPrice,
      tvaAmount: tvaAmount,
      totalPrice: totalTTC
    };
    
    setDeliveryDistance(deliveryDistance);
    setDeliveryPrice(deliveryPrice);
    setInvoiceData(invoiceData);
    setIsInvoiceModalOpen(true);
  };

  // Fermeture des modales
  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setSelectedRental(null);
  };

  // Fermeture de la modale de facture
  const closeInvoiceModal = () => {
    setIsInvoiceModalOpen(false);
    setInvoiceData(null);
  };

  const closeRefusalModal = () => {
    setIsRefusalModalOpen(false);
    setRefusalMessage('');
  };
  
  const closeQuoteModal = () => {
    setIsQuoteModalOpen(false);
    setQuoteData(null);
    setIsEditMode(false);
  };

  // Action pour refuser une location avec message
  const handleRefuseAction = async (rentalId, message) => {
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/refuse`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message })
      });
      if (res.ok) {
        fetchRentals(page);
        closeRefusalModal();
        closeDetailsModal();
      } else {
        const data = await res.json();
        alert("Erreur : " + data.error);
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du refus :", error);
      alert("Erreur lors de l'envoi du refus");
    }
  };

  // Fonction utilitaire pour convertir une image en base64
  const getBase64FromImageUrl = async (url) => {
    try {
      // Vérifiez si l'URL est relative ou absolue
      const absoluteUrl = url.startsWith('http') ? url : window.location.origin + url;
      
      const response = await fetch(absoluteUrl, {
        // Ajoutez ces options pour éviter les problèmes CORS
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const blob = await response.blob();
      
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Erreur lors de la conversion de l'image en base64:", error);
      // Au lieu de retourner null, retournez une URL de secours
      console.log("Utilisation d'une URL de secours pour le logo");
      return process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
    }
  };

  // const handleGenerateAndSendQuote = async (rentalId) => {
  //   try {
  //     // Capturer tous les styles appliqués à la page
  //     let stylesHTML = '';
  //     const styleSheets = document.styleSheets;
      
  //     for (let i = 0; i < styleSheets.length; i++) {
  //       const sheet = styleSheets[i];
  //       try {
  //         const rules = sheet.cssRules || sheet.rules;
  //         for (let j = 0; j < rules.length; j++) {
  //           stylesHTML += rules[j].cssText;
  //         }
  //       } catch (e) {
  //         console.log('Erreur lors de l\'accès aux règles CSS:', e);
  //       }
  //     }
      
  //     // Cloner l'élément pour ne pas modifier le DOM original
  //     const quoteElement = quoteRef.current.cloneNode(true);
      
  //     // Traiter spécifiquement le logo
  //     const logoImg = quoteElement.querySelector('.company-logo');
  //     if (logoImg) {
  //       try {
  //         // Utiliser directement l'URL complète du logo
  //         const logoUrl = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
  //         const base64Logo = await getBase64FromImageUrl(logoUrl);
  //         if (base64Logo) {
  //           logoImg.src = base64Logo;
  //         }
  //       } catch (error) {
  //         console.error("Erreur lors du chargement du logo:", error);
  //       }
  //     }
      
  //     // Remplacer toutes les autres images par leur version base64
  //     const images = quoteElement.querySelectorAll('img:not(.company-logo)');
  //     for (let i = 0; i < images.length; i++) {
  //       const img = images[i];
  //       const imgSrc = img.src;
        
  //       // Convertir l'image en base64
  //       const base64 = await getBase64FromImageUrl(imgSrc);
  //       if (base64) {
  //         img.src = base64;
  //       }
  //     }
      
  //     // Obtenir le HTML modifié
  //     const quoteHtmlContent = quoteElement.innerHTML;
      
  //     // Créer un HTML complet qui inclut tous les styles
  //     const completeHtml = `
  //       <!DOCTYPE html>
  //       <html>
  //       <head>
  //         <meta charset="UTF-8">
  //         <title>Devis ${quoteData.quoteNumber}</title>
  //         <style>
  //           ${stylesHTML}
  //           @page { size: A4; margin: 2cm; }
  //           body { font-family: Arial, sans-serif; }
  //           img { max-width: 100%; }
  //           .quote-company-info .company-logo {
  //             max-width: 200px;
  //             height: auto;
  //             margin-right: 20px;
  //             object-fit: contain;
  //             display: block;
  //           }
  //         </style>
  //       </head>
  //       <body>
  //         <div class="quote-preview">${quoteHtmlContent}</div>
  //       </body>
  //       </html>
  //     `;
      
  //     // Envoyer le HTML au backend
  //     const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/generate_and_send_quote`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ 
  //         html: completeHtml,
  //         delivery_distance: deliveryDistance,
  //         delivery_price: deliveryPrice,
  //         washing_option: quoteData.washingOption,  // Ajouter l'option lavage
  //         quote_data: quoteData
  //       })
  //     });
      
  //     const data = await res.json();
  //     if (res.ok) {
  //       alert("Le devis a bien été généré et envoyé par email.");
  //       fetchRentals(page);
  //       closeQuoteModal();
  //     } else {
  //       alert("Erreur : " + data.error);
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors de la génération et de l'envoi du devis :", error);
  //     alert("Erreur lors de la génération et de l'envoi du devis");
  //   }
  // };

// Fonction pour générer et envoyer le devis par email
const handleGenerateAndSendQuote = async (rentalId) => {
  try {

    if (quoteData.deliveryMethod === 'delivery' && deliveryPrice <= 0) {
      alert("Attention: Le prix de livraison est à 0€. Veuillez saisir un tarif kilométrique avant d'envoyer le devis.");
      return;
    }
    // Capturer tous les styles appliqués à la page
    let stylesHTML = '';
    const styleSheets = document.styleSheets;
    
    for (let i = 0; i < styleSheets.length; i++) {
      const sheet = styleSheets[i];
      try {
        const rules = sheet.cssRules || sheet.rules;
        for (let j = 0; j < rules.length; j++) {
          stylesHTML += rules[j].cssText;
        }
      } catch (e) {
        console.log('Erreur lors de l\'accès aux règles CSS:', e);
      }
    }
    
    // Cloner l'élément pour ne pas modifier le DOM original
    const quoteElement = quoteRef.current.cloneNode(true);
    
    // Traiter spécifiquement le logo
    const logoImg = quoteElement.querySelector('.company-logo');
    let logoSrc = '';
    if (logoImg) {
      try {
        // Utiliser directement l'URL complète du logo
        const logoUrl = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
        const base64Logo = await getBase64FromImageUrl(logoUrl);
        if (base64Logo) {
          logoSrc = base64Logo;
        }
      } catch (error) {
        console.error("Erreur lors du chargement du logo:", error);
      }
    }
    
    // Extraire le contenu du devis sans l'en-tête pour le recréer manuellement
    const headerElement = quoteElement.querySelector('.quote-header-section');
    if (headerElement) {
      headerElement.remove();
    }
    const quoteContentWithoutHeader = quoteElement.innerHTML;
    
    // Créer un HTML complet qui inclut tous les styles
    const completeHtml = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>Devis ${quoteData.quoteNumber}</title>
        <style>
          ${stylesHTML}
          @page { 
            size: A4; 
            margin: 2cm;
          }
          body { 
            font-family: Arial, sans-serif;
            color: #333;
            line-height: 1.4;
          }
          .quote-preview {
            max-width: 800px;
            margin: 0 auto;
          }
          .quote-header-section {
            display: flex;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
          }
          .logo-container {
            width: 100px;
            min-width: 100px;
            margin-right: 20px;
          }
          .company-logo {
            max-width: 100%;
            height: auto;
            object-fit: contain;
          }
          .company-details {
            flex-grow: 1;
            min-width: 200px;
          }
          .quote-reference {
            min-width: 180px;
            text-align: right;
          }
          .quote-reference h2 {
            color: #C9A77C;
            font-size: 28px;
            margin-top: 0;
          }
          .quote-items-table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            page-break-inside: avoid; /* Évite les coupures à l'intérieur du tableau */
          }
          .quote-items-table th {
            background-color: #444;
            color: white;
            padding: 10px;
            text-align: left;
          }
          .quote-items-table td {
            padding: 8px 10px;
            border-bottom: 1px solid #ddd;
          }
          .quote-items-table tfoot tr {
            background-color: #f8f8f8;
          }
          .quote-items-table tfoot td {
            padding: 10px;
            font-weight: bold;
          }
          .total-row {
            font-weight: bold;
            background-color: #f0f0f0;
          }
          .deposit-row {
            color: #C9A77C;
          }
          .quote-terms {
            margin-top: 20px;
            padding: 15px;
            background-color: #f8f8f8;
            border-radius: 4px;
          }
          .text-right {
            text-align: right;
          }
          .text-center {
            text-align: center;
          }
          
          /* Pour empêcher les coupures inappropriées */
          .quote-client-info, .quote-details, .quote-terms {
            page-break-inside: avoid;
          }
        </style>
      </head>
      <body>
        <div class="quote-preview">
          <div class="quote-header-section">
            <div class="logo-container">
              <img src="${logoSrc}" alt="Logo" class="company-logo" />
            </div>
            <div class="company-details">
              <h3>${companyInfo.name}</h3>
              <p>${companyInfo.address}</p>
              <p>${companyInfo.zipCode} ${companyInfo.city}</p>
              <p>Tél: ${companyInfo.phone}</p>
              <p>Email: ${companyInfo.email}</p>
              <p>SIRET: ${companyInfo.siret}</p>
            </div>
            <div class="quote-reference">
              <h2>DEVIS</h2>
              <p><strong>N°:</strong> ${quoteData.quoteNumber}</p>
              <p><strong>Date:</strong> ${new Date().toLocaleDateString('fr-FR')}</p>
              <p><strong>Validité:</strong> 30 jours</p>
            </div>
          </div>
          
          ${quoteContentWithoutHeader}
        </div>
      </body>
      </html>
    `;
    
    // Envoyer le HTML au backend
    const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/generate_and_send_quote`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        html: completeHtml,
        delivery_distance: deliveryDistance,
        delivery_price: deliveryPrice,
        washing_option: quoteData.washingOption,
        quote_data: quoteData
      })
    });
    
    const data = await res.json();
    if (res.ok) {
      alert("Le devis a bien été généré et envoyé par email.");
      fetchRentals(page);
      closeQuoteModal();
    } else {
      alert("Erreur : " + data.error);
    }
  } catch (error) {
    console.error("Erreur lors de la génération et de l'envoi du devis :", error);
    alert("Erreur lors de la génération et de l'envoi du devis");
  }
};

  // // Fonction pour générer et envoyer la facture
  // const handleGenerateAndSendInvoice = async (rentalId) => {
  //   try {
  //     // Capturer tous les styles appliqués à la page
  //     let stylesHTML = '';
  //     const styleSheets = document.styleSheets;
      
  //     for (let i = 0; i < styleSheets.length; i++) {
  //       const sheet = styleSheets[i];
  //       try {
  //         const rules = sheet.cssRules || sheet.rules;
  //         for (let j = 0; j < rules.length; j++) {
  //           stylesHTML += rules[j].cssText;
  //         }
  //       } catch (e) {
  //         console.log('Erreur lors de l\'accès aux règles CSS:', e);
  //       }
  //     }
      
  //     // Cloner l'élément pour ne pas modifier le DOM original
  //     const invoiceElement = invoiceRef.current.cloneNode(true);
      
  //     // Traiter spécifiquement le logo
  //     const logoImg = invoiceElement.querySelector('.company-logo');
  //     if (logoImg) {
  //       try {
  //         // Utiliser directement l'URL complète du logo
  //         const logoUrl = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
  //         const base64Logo = await getBase64FromImageUrl(logoUrl);
  //         if (base64Logo) {
  //           logoImg.src = base64Logo;
  //         }
  //       } catch (error) {
  //         console.error("Erreur lors du chargement du logo:", error);
  //       }
  //     }
      
  //     // Remplacer toutes les autres images par leur version base64
  //     const images = invoiceElement.querySelectorAll('img:not(.company-logo)');
  //     for (let i = 0; i < images.length; i++) {
  //       const img = images[i];
  //       const imgSrc = img.src;
        
  //       // Convertir l'image en base64
  //       const base64 = await getBase64FromImageUrl(imgSrc);
  //       if (base64) {
  //         img.src = base64;
  //       }
  //     }
      
  //     // Obtenir le HTML modifié
  //     const invoiceHtmlContent = invoiceElement.innerHTML;
      
  //     // Créer un HTML complet qui inclut tous les styles
  //     const completeHtml = `
  //       <!DOCTYPE html>
  //       <html>
  //       <head>
  //         <meta charset="UTF-8">
  //         <title>Facture ${invoiceData.invoiceNumber}</title>
  //         <style>
  //           ${stylesHTML}
  //           @page { size: A4; margin: 2cm; }
  //           body { font-family: Arial, sans-serif; }
  //           img { max-width: 100%; }
  //           .invoice-company-info .company-logo {
  //             max-width: 200px;
  //             height: auto;
  //             margin-right: 20px;
  //             object-fit: contain;
  //             display: block;
  //           }
  //         </style>
  //       </head>
  //       <body>
  //         <div class="invoice-preview">${invoiceHtmlContent}</div>
  //       </body>
  //       </html>
  //     `;
      
  //     // Envoyer le HTML au backend
  //     const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/generate_and_send_invoice`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ 
  //         html: completeHtml,
  //         delivery_distance: deliveryDistance,
  //         delivery_price: deliveryPrice,
  //         washing_option: invoiceData.washingOption,  // Inclure l'option lavage
  //         invoice_data: invoiceData
  //       })
  //     });
      
  //     const data = await res.json();
  //     if (res.ok) {
  //       alert("La facture a bien été générée et envoyée par email.");
  //       fetchRentals(page);
  //       closeInvoiceModal();
  //     } else {
  //       alert("Erreur : " + data.error);
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors de la génération et de l'envoi de la facture :", error);
  //     alert("Erreur lors de la génération et de l'envoi de la facture");
  //   }
  // };


 // Fonction pour générer et envoyer la facture par email
const handleGenerateAndSendInvoice = async (rentalId) => {
  try {

    if (invoiceData.deliveryMethod === 'delivery' && deliveryPrice <= 0) {
      alert("Attention: Le prix de livraison est à 0€. Veuillez saisir un tarif kilométrique avant d'envoyer la facture.");
      return;
    }
    
    // Capturer tous les styles appliqués à la page
    let stylesHTML = '';
    const styleSheets = document.styleSheets;
    
    for (let i = 0; i < styleSheets.length; i++) {
      const sheet = styleSheets[i];
      try {
        const rules = sheet.cssRules || sheet.rules;
        for (let j = 0; j < rules.length; j++) {
          stylesHTML += rules[j].cssText;
        }
      } catch (e) {
        console.log('Erreur lors de l\'accès aux règles CSS:', e);
      }
    }
    
    // Cloner l'élément pour ne pas modifier le DOM original
    const invoiceElement = invoiceRef.current.cloneNode(true);
    
    // Traiter spécifiquement le logo
    const logoImg = invoiceElement.querySelector('.company-logo');
    let logoSrc = '';
    if (logoImg) {
      try {
        // Utiliser directement l'URL complète du logo
        const logoUrl = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
        const base64Logo = await getBase64FromImageUrl(logoUrl);
        if (base64Logo) {
          logoSrc = base64Logo;
        }
      } catch (error) {
        console.error("Erreur lors du chargement du logo:", error);
      }
    }
    
    // Extraire le contenu de la facture sans l'en-tête pour le recréer manuellement
    const headerElement = invoiceElement.querySelector('.invoice-header-section');
    if (headerElement) {
      headerElement.remove();
    }
    const invoiceContentWithoutHeader = invoiceElement.innerHTML;
    
    // Créer un HTML complet qui inclut tous les styles
    const completeHtml = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>Facture ${invoiceData.invoiceNumber}</title>
        <style>
          ${stylesHTML}
          @page { 
            size: A4; 
            margin: 2cm;
          }
          body { 
            font-family: Arial, sans-serif;
            color: #333;
            line-height: 1.4;
          }
          .invoice-preview {
            max-width: 800px;
            margin: 0 auto;
          }
          .invoice-header-section {
            display: flex;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
          }
          .logo-container {
            width: 100px;
            min-width: 100px;
            margin-right: 20px;
          }
          .company-logo {
            max-width: 100%;
            height: auto;
            object-fit: contain;
          }
          .company-details {
            flex-grow: 1;
            min-width: 200px;
          }
          .invoice-reference {
            min-width: 180px;
            text-align: right;
          }
          .invoice-reference h2 {
            color: #C9A77C;
            font-size: 28px;
            margin-top: 0;
          }
          .invoice-items-table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            page-break-inside: avoid; /* Évite les coupures à l'intérieur du tableau */
          }
          .invoice-items-table th {
            background-color: #444;
            color: white !important;
            padding: 10px;
            text-align: left;
          }
          .invoice-items-table td {
            padding: 8px 10px;
            border-bottom: 1px solid #ddd;
          }
          .invoice-items-table tfoot tr {
            background-color: #f8f8f8;
          }
          .invoice-items-table tfoot td {
            padding: 10px;
            font-weight: bold;
          }
          .total-row {
            font-weight: bold;
            background-color: #f0f0f0;
          }
          .paid-deposit-row td {
            color: #2c8a2c;
          }
          .remaining-amount-row td {
            font-weight: bold;
            color: #C9A77C;
          }
          .invoice-terms {
            margin-top: 20px;
            padding: 15px;
            background-color: #f8f8f8;
            border-radius: 4px;
          }
          .text-right {
            text-align: right;
          }
          .text-center {
            text-align: center;
          }
          
          /* Pour empêcher les coupures inappropriées */
          .invoice-client-info, .invoice-details, .invoice-terms {
            page-break-inside: avoid;
          }
        </style>
      </head>
      <body>
        <div class="invoice-preview">
          <div class="invoice-header-section">
            <div class="logo-container">
              <img src="${logoSrc}" alt="Logo" class="company-logo" />
            </div>
            <div class="company-details">
              <h3>${companyInfo.name}</h3>
              <p>${companyInfo.address}</p>
              <p>${companyInfo.zipCode} ${companyInfo.city}</p>
              <p>Tél: ${companyInfo.phone}</p>
              <p>Email: ${companyInfo.email}</p>
              <p>SIRET: ${companyInfo.siret}</p>
            </div>
            <div class="invoice-reference">
              <h2>FACTURE</h2>
              <p><strong>N°:</strong> ${invoiceData.invoiceNumber}</p>
              <p><strong>Date:</strong> ${new Date().toLocaleDateString('fr-FR')}</p>
            </div>
          </div>
          
          ${invoiceContentWithoutHeader}
        </div>
      </body>
      </html>
    `;
    
    // Envoyer le HTML au backend
    const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/generate_and_send_invoice`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        html: completeHtml,
        delivery_distance: deliveryDistance,
        delivery_price: deliveryPrice,
        washing_option: invoiceData.washingOption,
        invoice_data: invoiceData
      })
    });
    
    const data = await res.json();
    if (res.ok) {
      alert("La facture a bien été générée et envoyée par email.");
      fetchRentals(page);
      closeInvoiceModal();
    } else {
      alert("Erreur : " + data.error);
    }
  } catch (error) {
    console.error("Erreur lors de la génération et de l'envoi de la facture :", error);
    alert("Erreur lors de la génération et de l'envoi de la facture");
  }
};

  // // Télécharger la facture en PDF
  // const handleDownloadInvoice = async () => {
  //   try {
  //     // Capturer tous les styles appliqués à la page
  //     let stylesHTML = '';
  //     const styleSheets = document.styleSheets;
      
  //     for (let i = 0; i < styleSheets.length; i++) {
  //       const sheet = styleSheets[i];
  //       try {
  //         const rules = sheet.cssRules || sheet.rules;
  //         for (let j = 0; j < rules.length; j++) {
  //           stylesHTML += rules[j].cssText;
  //         }
  //       } catch (e) {
  //         console.log('Erreur lors de l\'accès aux règles CSS:', e);
  //       }
  //     }
      
  //     // Cloner l'élément pour ne pas modifier le DOM original
  //     const invoiceElement = invoiceRef.current.cloneNode(true);
      
  //     // Remplacer toutes les images par leur version base64
  //     const images = invoiceElement.querySelectorAll('img');
  //     for (let i = 0; i < images.length; i++) {
  //       const img = images[i];
  //       const imgSrc = img.src;
        
  //       // Convertir l'image en base64
  //       const base64 = await getBase64FromImageUrl(imgSrc);
  //       if (base64) {
  //         img.src = base64;
  //       }
  //     }
      
  //     // Obtenir le HTML modifié
  //     const invoiceHtmlContent = invoiceElement.innerHTML;
      
  //     // Créer un HTML complet qui inclut tous les styles
  //     const completeHtml = `
  //       <!DOCTYPE html>
  //       <html>
  //       <head>
  //         <meta charset="UTF-8">
  //         <title>Facture ${invoiceData.invoiceNumber}</title>
  //         <style>
  //           ${stylesHTML}
  //           @page { size: A4; margin: 2cm; }
  //           body { font-family: Arial, sans-serif; }
  //           img { max-width: 100%; }
  //         </style>
  //       </head>
  //       <body>
  //         <div class="invoice-preview">${invoiceHtmlContent}</div>
  //       </body>
  //       </html>
  //     `;
      
  //     // Envoyer la requête pour télécharger le PDF
  //     const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${invoiceData.id}/download_invoice`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ 
  //         html: completeHtml,
  //         delivery_distance: deliveryDistance,
  //         delivery_price: deliveryPrice,
  //         washing_option: invoiceData.washingOption,
  //         invoice_data: invoiceData
  //       })
  //     });
      
  //     // Si succès, récupérer le PDF et le télécharger
  //     if (res.ok) {
  //       const blob = await res.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = `Facture-${invoiceData.invoiceNumber}.pdf`;
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       a.remove();
  //     } else {
  //       const data = await res.json();
  //       alert("Erreur : " + data.error);
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors du téléchargement de la facture :", error);
  //     alert("Erreur lors du téléchargement de la facture");
  //   }
  // };


  // Télécharger la facture en PDF
// Télécharger la facture en PDF
const handleDownloadInvoice = async () => {
  try {

    if (invoiceData.deliveryMethod === 'delivery' && deliveryPrice <= 0) {
      alert("Attention: Le prix de livraison est à 0€. Veuillez saisir un tarif kilométrique avant de télécharger la facture.");
      return;
    }
    // Capturer tous les styles appliqués à la page
    let stylesHTML = '';
    const styleSheets = document.styleSheets;
    
    for (let i = 0; i < styleSheets.length; i++) {
      const sheet = styleSheets[i];
      try {
        const rules = sheet.cssRules || sheet.rules;
        for (let j = 0; j < rules.length; j++) {
          stylesHTML += rules[j].cssText;
        }
      } catch (e) {
        console.log('Erreur lors de l\'accès aux règles CSS:', e);
      }
    }
    
    // Cloner l'élément pour ne pas modifier le DOM original
    const invoiceElement = invoiceRef.current.cloneNode(true);
    
    // Traiter spécifiquement le logo
    const logoImg = invoiceElement.querySelector('.company-logo');
    let logoSrc = '';
    if (logoImg) {
      try {
        // Utiliser directement l'URL complète du logo
        const logoUrl = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
        const base64Logo = await getBase64FromImageUrl(logoUrl);
        if (base64Logo) {
          logoSrc = base64Logo;
        }
      } catch (error) {
        console.error("Erreur lors du chargement du logo:", error);
      }
    }
    
    // Extraire le contenu de la facture sans l'en-tête pour le recréer manuellement
    const headerElement = invoiceElement.querySelector('.invoice-header-section');
    if (headerElement) {
      headerElement.remove();
    }
    const invoiceContentWithoutHeader = invoiceElement.innerHTML;
    
    // Créer un HTML complet qui inclut tous les styles
    const completeHtml = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>Facture ${invoiceData.invoiceNumber}</title>
        <style>
          ${stylesHTML}
          @page { 
            size: A4; 
            margin: 2cm;
          }
          body { 
            font-family: Arial, sans-serif;
            color: #333;
            line-height: 1.4;
          }
          .invoice-preview {
            max-width: 800px;
            margin: 0 auto;
          }
          .invoice-header-section {
            display: flex;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
          }
          .logo-container {
            width: 100px;
            min-width: 100px;
            margin-right: 20px;
          }
          .company-logo {
            max-width: 100%;
            height: auto;
            object-fit: contain;
          }
          .company-details {
            flex-grow: 1;
            min-width: 200px;
          }
          .invoice-reference {
            min-width: 180px;
            text-align: right;
          }
          .invoice-reference h2 {
            color: #C9A77C;
            font-size: 28px;
            margin-top: 0;
          }
          .invoice-items-table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            page-break-inside: avoid; /* Évite les coupures à l'intérieur du tableau */
          }
          .invoice-items-table th {
            background-color: #444;
            color: white !important;
            padding: 10px;
            text-align: left;
          }
          .invoice-items-table td {
            padding: 8px 10px;
            border-bottom: 1px solid #ddd;
          }
          .invoice-items-table tfoot tr {
            background-color: #f8f8f8;
          }
          .invoice-items-table tfoot td {
            padding: 10px;
            font-weight: bold;
          }
          .total-row {
            font-weight: bold;
            background-color: #f0f0f0;
          }
          .paid-deposit-row td {
            color: #2c8a2c;
          }
          .remaining-amount-row td {
            font-weight: bold;
            color: #C9A77C;
          }
          .invoice-terms {
            margin-top: 20px;
            padding: 15px;
            background-color: #f8f8f8;
            border-radius: 4px;
          }
          .text-right {
            text-align: right;
          }
          .text-center {
            text-align: center;
          }
          
          /* Pour empêcher les coupures inappropriées */
          .invoice-client-info, .invoice-details, .invoice-terms {
            page-break-inside: avoid;
          }
        </style>
      </head>
      <body>
        <div class="invoice-preview">
          <div class="invoice-header-section">
            <div class="logo-container">
              <img src="${logoSrc}" alt="Logo" class="company-logo" />
            </div>
            <div class="company-details">
              <h3>${companyInfo.name}</h3>
              <p>${companyInfo.address}</p>
              <p>${companyInfo.zipCode} ${companyInfo.city}</p>
              <p>Tél: ${companyInfo.phone}</p>
              <p>Email: ${companyInfo.email}</p>
              <p>SIRET: ${companyInfo.siret}</p>
            </div>
            <div class="invoice-reference">
              <h2>FACTURE</h2>
              <p><strong>N°:</strong> ${invoiceData.invoiceNumber}</p>
              <p><strong>Date:</strong> ${new Date().toLocaleDateString('fr-FR')}</p>
            </div>
          </div>
          
          ${invoiceContentWithoutHeader}
        </div>
      </body>
      </html>
    `;
    
    // Envoyer la requête pour télécharger le PDF
    const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${invoiceData.id}/download_invoice`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        html: completeHtml,
        delivery_distance: deliveryDistance,
        delivery_price: deliveryPrice,
        washing_option: invoiceData.washingOption,
        invoice_data: invoiceData
      })
    });
    
    // Si succès, récupérer le PDF et le télécharger
    if (res.ok) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Facture-${invoiceData.invoiceNumber}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } else {
      const data = await res.json();
      alert("Erreur : " + data.error);
    }
  } catch (error) {
    console.error("Erreur lors du téléchargement de la facture :", error);
    alert("Erreur lors du téléchargement de la facture");
  }
};
  
  // // Télécharger le devis en PDF
  // const handleDownloadQuote = async () => {
  //   try {
  //     // Capturer tous les styles appliqués à la page
  //     let stylesHTML = '';
  //     const styleSheets = document.styleSheets;
      
  //     for (let i = 0; i < styleSheets.length; i++) {
  //       const sheet = styleSheets[i];
  //       try {
  //         const rules = sheet.cssRules || sheet.rules;
  //         for (let j = 0; j < rules.length; j++) {
  //           stylesHTML += rules[j].cssText;
  //         }
  //       } catch (e) {
  //         console.log('Erreur lors de l\'accès aux règles CSS:', e);
  //       }
  //     }
      
  //     // Cloner l'élément pour ne pas modifier le DOM original
  //     const quoteElement = quoteRef.current.cloneNode(true);
      
  //     // Traiter spécifiquement le logo
  //     const logoImg = quoteElement.querySelector('.company-logo');
  //     if (logoImg) {
  //       try {
  //         // Utiliser directement l'URL complète du logo
  //         const logoUrl = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
  //         const base64Logo = await getBase64FromImageUrl(logoUrl);
  //         if (base64Logo) {
  //           logoImg.src = base64Logo;
  //         }
  //       } catch (error) {
  //         console.error("Erreur lors du chargement du logo:", error);
  //       }
  //     }
      
  //     // Remplacer toutes les autres images par leur version base64
  //     const images = quoteElement.querySelectorAll('img:not(.company-logo)');
  //     for (let i = 0; i < images.length; i++) {
  //       const img = images[i];
  //       const imgSrc = img.src;
        
  //       // Convertir l'image en base64
  //       const base64 = await getBase64FromImageUrl(imgSrc);
  //       if (base64) {
  //         img.src = base64;
  //       }
  //     }
      
  //     // Obtenir le HTML modifié
  //     const quoteHtmlContent = quoteElement.innerHTML;
      
  //     // Créer un HTML complet qui inclut tous les styles
  //     const completeHtml = `
  //       <!DOCTYPE html>
  //       <html>
  //       <head>
  //         <meta charset="UTF-8">
  //         <title>Devis ${quoteData.quoteNumber}</title>
  //         <style>
  //           ${stylesHTML}
  //           @page { size: A4; margin: 2cm; }
  //           body { font-family: Arial, sans-serif; }
  //           img { max-width: 100%; }
  //           .quote-company-info .company-logo {
  //             max-width: 200px;
  //             height: auto;
  //             margin-right: 20px;
  //             object-fit: contain;
  //             display: block;
  //           }
  //         </style>
  //       </head>
  //       <body>
  //         <div class="quote-preview">${quoteHtmlContent}</div>
  //       </body>
  //       </html>
  //     `;
      
  //     // Envoyer la requête pour télécharger le PDF
  //     const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${quoteData.id}/download_quote`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ 
  //         html: completeHtml,
  //         delivery_distance: deliveryDistance,
  //         delivery_price: deliveryPrice,
  //         washing_option: quoteData.washingOption,
  //         quote_data: quoteData
  //       })
  //     });
      
  //     // Si succès, récupérer le PDF et le télécharger
  //     if (res.ok) {
  //       const blob = await res.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = `Devis-${quoteData.quoteNumber}.pdf`;
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       a.remove();
  //     } else {
  //       const data = await res.json();
  //       alert("Erreur : " + data.error);
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors du téléchargement du devis :", error);
  //     alert("Erreur lors du téléchargement du devis");
  //   }
  // };

// Télécharger le devis en PDF
const handleDownloadQuote = async () => {
  try {

    if (quoteData.deliveryMethod === 'delivery' && deliveryPrice <= 0) {
      alert("Attention: Le prix de livraison est à 0€. Veuillez saisir un tarif kilométrique avant de télécharger le devis.");
      return;
    }
    // Capturer tous les styles appliqués à la page
    let stylesHTML = '';
    const styleSheets = document.styleSheets;
    
    for (let i = 0; i < styleSheets.length; i++) {
      const sheet = styleSheets[i];
      try {
        const rules = sheet.cssRules || sheet.rules;
        for (let j = 0; j < rules.length; j++) {
          stylesHTML += rules[j].cssText;
        }
      } catch (e) {
        console.log('Erreur lors de l\'accès aux règles CSS:', e);
      }
    }
    
    // Cloner l'élément pour ne pas modifier le DOM original
    const quoteElement = quoteRef.current.cloneNode(true);
    
    // Traiter spécifiquement le logo
    const logoImg = quoteElement.querySelector('.company-logo');
    let logoSrc = '';
    if (logoImg) {
      try {
        // Utiliser directement l'URL complète du logo
        const logoUrl = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
        const base64Logo = await getBase64FromImageUrl(logoUrl);
        if (base64Logo) {
          logoSrc = base64Logo;
        }
      } catch (error) {
        console.error("Erreur lors du chargement du logo:", error);
      }
    }
    
    // Extraire le contenu du devis sans l'en-tête pour le recréer manuellement
    const headerElement = quoteElement.querySelector('.quote-header-section');
    if (headerElement) {
      headerElement.remove();
    }
    const quoteContentWithoutHeader = quoteElement.innerHTML;
    
    // Créer un HTML complet qui inclut tous les styles
    const completeHtml = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>Devis ${quoteData.quoteNumber}</title>
        <style>
          ${stylesHTML}
          @page { 
            size: A4; 
            margin: 2cm;
          }
          body { 
            font-family: Arial, sans-serif;
            color: #333;
            line-height: 1.4;
          }
          .quote-preview {
            max-width: 800px;
            margin: 0 auto;
          }
          .quote-header-section {
            display: flex;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
          }
          .logo-container {
            width: 100px;
            min-width: 100px;
            margin-right: 20px;
          }
          .company-logo {
            max-width: 100%;
            height: auto;
            object-fit: contain;
          }
          .company-details {
            flex-grow: 1;
            min-width: 200px;
          }
          .quote-reference {
            min-width: 180px;
            text-align: right;
          }
          .quote-reference h2 {
            color: #C9A77C;
            font-size: 28px;
            margin-top: 0;
          }
          .quote-items-table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            page-break-inside: avoid; /* Évite les coupures à l'intérieur du tableau */
          }
          .quote-items-table th {
            background-color: #444;
            color: white;
            padding: 10px;
            text-align: left;
          }
          .quote-items-table td {
            padding: 8px 10px;
            border-bottom: 1px solid #ddd;
          }
          .quote-items-table tfoot tr {
            background-color: #f8f8f8;
          }
          .quote-items-table tfoot td {
            padding: 10px;
            font-weight: bold;
          }
          .total-row {
            font-weight: bold;
            background-color: #f0f0f0;
          }
          .deposit-row {
            color: #C9A77C;
          }
          .quote-terms {
            margin-top: 20px;
            padding: 15px;
            background-color: #f8f8f8;
            border-radius: 4px;
          }
          .text-right {
            text-align: right;
          }
          .text-center {
            text-align: center;
          }
          
          /* Pour empêcher les coupures inappropriées */
          .quote-client-info, .quote-details, .quote-terms {
            page-break-inside: avoid;
          }
        </style>
      </head>
      <body>
        <div class="quote-preview">
          <div class="quote-header-section">
            <div class="logo-container">
              <img src="${logoSrc}" alt="Logo" class="company-logo" />
            </div>
            <div class="company-details">
              <h3>${companyInfo.name}</h3>
              <p>${companyInfo.address}</p>
              <p>${companyInfo.zipCode} ${companyInfo.city}</p>
              <p>Tél: ${companyInfo.phone}</p>
              <p>Email: ${companyInfo.email}</p>
              <p>SIRET: ${companyInfo.siret}</p>
            </div>
            <div class="quote-reference">
              <h2>DEVIS</h2>
              <p><strong>N°:</strong> ${quoteData.quoteNumber}</p>
              <p><strong>Date:</strong> ${new Date().toLocaleDateString('fr-FR')}</p>
              <p><strong>Validité:</strong> 30 jours</p>
            </div>
          </div>
          
          ${quoteContentWithoutHeader}
        </div>
      </body>
      </html>
    `;
    
    // Envoyer la requête pour télécharger le PDF
    const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${quoteData.id}/download_quote`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        html: completeHtml,
        delivery_distance: deliveryDistance,
        delivery_price: deliveryPrice,
        washing_option: quoteData.washingOption,
        quote_data: quoteData
      })
    });
    
    // Si succès, récupérer le PDF et le télécharger
    if (res.ok) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Devis-${quoteData.quoteNumber}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } else {
      const data = await res.json();
      alert("Erreur : " + data.error);
    }
  } catch (error) {
    console.error("Erreur lors du téléchargement du devis :", error);
    alert("Erreur lors du téléchargement du devis");
  }
};
  
  // Fonction pour mettre à jour les informations du devis
  const handleUpdateQuote = async () => {
    try {
   
      // Mise à jour des données dans la base de données
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${quoteData.id}/update_quote`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          items: quoteData.items, 
          total_price: quoteData.totalPrice,
          delivery_distance: deliveryDistance,
          delivery_price: deliveryPrice,
          washing_option: quoteData.washingOption  // Ajouter l'option lavage
        })
      });
      
      if (res.ok) {
        alert("Les modifications du devis ont été enregistrées.");
        setIsEditMode(false);
        
        // Rafraîchir les données
        fetchRentals(page);
      } else {
        const data = await res.json();
        alert("Erreur : " + data.error);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du devis :", error);
      alert("Erreur lors de la mise à jour du devis");
    }
  };
  
  // Fonction pour modifier un élément du devis
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...quoteData.items];
    updatedItems[index][field] = parseFloat(value);
    
    // Recalculer le sous-total pour cet article
    updatedItems[index].subtotal = quoteData.days * updatedItems[index].quantity * updatedItems[index].daily_price;
    
    // Recalculer le sous-total général des articles
    const newSubtotal = updatedItems.reduce((total, item) => total + item.subtotal, 0);
    
    // Calculer le total HT (articles + livraison + option lavage)
    const totalHT = newSubtotal + deliveryPrice + (quoteData.washingOption ? 20 : 0);
    
    // Recalculer la TVA
    const totalTVA = totalHT * (companyInfo.tvaRate / 100);
    
    // Recalculer le total général
    const totalTTC = totalHT + totalTVA;
    
    setQuoteData({
      ...quoteData,
      items: updatedItems,
      subtotal: newSubtotal,
      totalPrice: totalTTC
    });
  };
  
  // Gérer le changement de l'option de lavage
  const handleWashingOptionChange = () => {
    const newWashingOption = !quoteData.washingOption;
    const washingPrice = newWashingOption ? 20 : 0;
    
    // Recalculer le prix total avec ou sans option lavage
    const totalHT = quoteData.subtotal + deliveryPrice + washingPrice;
    const totalTVA = totalHT * (companyInfo.tvaRate / 100);
    const totalTTC = totalHT + totalTVA;
    
    setQuoteData({
      ...quoteData,
      washingOption: newWashingOption,
      washingPrice: washingPrice,
      totalPrice: totalTTC
    });
  };
  
  const StatusProgress = ({ status }) => {
    const getProgress = (currentStatus) => {
      const statusFlow = ['en attente', 'devis envoyé par mail', 'paid', 'facture envoyée'];
      const index = statusFlow.indexOf(currentStatus.toLowerCase());
      return index >= 0 ? (index + 1) * 20 : 0;
    };

    // Si refusé ou décliné, afficher un indicateur différent
    if (status.toLowerCase() === 'refused' || status.toLowerCase() === 'décliner') {
      return (
        <div className="status-progress canceled">
          <span>{status}</span>
        </div>
      );
    }

    return (
      <div className="status-progress">
        <div className="progress-bar">
          <div 
            className="progress-fill" 
            style={{ width: `${getProgress(status)}%` }} 
          />
        </div>
        <span>{status}</span>
      </div>
    );
  };

  // Formater la date au format français
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  // Gestion de la pagination
  const handlePrevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <div className="admin-rentals-page">
      <h1>Gestion des Locations</h1>
      <div className="status-legend">
        <h3>Légende des statuts</h3>
        <div className="legend-item">
          <div className="legend-color" data-status="en attente"></div>
          <span>En attente</span>
        </div>
        <div className="legend-item">
          <div className="legend-color" data-status="devis envoyé par mail"></div>
          <span>Devis envoyé</span>
        </div>
        <div className="legend-item">
          <div className="legend-color" data-status="paid"></div>
          <span>Acompte payé</span>
        </div>
        <div className="legend-item">
          <div className="legend-color" data-status="décliner"></div>
          <span>Décliné par client</span>
        </div>
        <div className="legend-item">
          <div className="legend-color" data-status="facture envoyée"></div>
          <span>Facture envoyée</span>
        </div>
        <div className="legend-item">
          <div className="legend-color" data-status="refused"></div>
          <span>Refusé par admin</span>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Client</th>
            <th>Email</th>
            <th>Période</th>
            <th>Total TTC</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rentals.map(rental => (
            <tr key={rental.id} onClick={() => openDetailsModal(rental)} data-status={rental.status.toLowerCase()}>
              <td>{rental.id}</td>
              <td>{rental.first_name} {rental.last_name}</td>
              <td>{rental.email}</td>
              <td>{formatDate(rental.start_date)} - {formatDate(rental.end_date)}</td>
              <td>{rental.total_price} €</td>
              <td data-status={rental.status.toLowerCase()}>{rental.status}</td>
              <td onClick={e => e.stopPropagation()}>
                <button style={{ 
                  backgroundColor: '#3f51b5', 
                  color: 'white', 
                  padding: '8px 12px',
                  margin: '3px',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }} onClick={() => openQuoteModal(rental)}>
                  Voir devis
                </button>
                
                <button style={{ 
                  backgroundColor: '#3f51b5', 
                  color: 'white', 
                  padding: '8px 12px',
                  margin: '3px',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }} onClick={() => openInvoiceModal(rental)}>
                  Voir facture
                </button>
                
                <button 
                  style={{ 
                    backgroundColor: '#3f51b5', 
                    color: 'white', 
                    padding: '8px 12px',
                    margin: '3px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }} 
                  onClick={() => {
                    if (rental.status === 'en attente' || rental.status === 'devis envoyé par mail') {
                      setSelectedRental(rental);
                      setIsRefusalModalOpen(true);
                    } else {
                      alert("Cette location ne peut plus être refusée car son statut est : " + rental.status);
                    }
                  }}
                >
                  Refuser
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={page === 1}>Précédent</button>
        <span>Page {page} sur {totalPages}</span>
        <button onClick={handleNextPage} disabled={page === totalPages}>Suivant</button>
      </div>

      {/* Modale de détails */}
      {isDetailsModalOpen && selectedRental && (
        <div className="modal-overlay" onClick={closeDetailsModal}>
          <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Détails de la Location #{selectedRental.id}</h2>
              <button onClick={closeDetailsModal}>×</button>
            </div>
            <div className="modal-content">
              <h3>Informations client</h3>
              <div className="details-section">
                <p><strong>Nom:</strong> {selectedRental.first_name} {selectedRental.last_name}</p>
                <p><strong>Email:</strong> {selectedRental.email}</p>
                <p><strong>Téléphone:</strong> {selectedRental.phone || "Non renseigné"}</p>
              </div>
              
              <h3>Détails de la location</h3>
              <div className="details-section">
                <p><strong>Période:</strong> {formatDate(selectedRental.start_date)} - {formatDate(selectedRental.end_date)}</p>
                <p><strong>Statut:</strong> <span className="status-badge" data-status={selectedRental.status.toLowerCase()}>{selectedRental.status}</span></p>
                <p><strong>Mode de livraison:</strong> {selectedRental.delivery_method === 'delivery' ? 'Livraison' : 'Récupération sur place'}</p>
                
                {selectedRental.delivery_method === 'delivery' && (
                  <div className="delivery-details">
                    <p><strong>Adresse de livraison:</strong></p>
                    <p>{selectedRental.delivery_street}</p>
                    <p>{selectedRental.delivery_zip_code} {selectedRental.delivery_city}</p>
                    {selectedRental.delivery_distance > 0 && (
                      <p><strong>Distance:</strong> {selectedRental.delivery_distance} km (Prix: {selectedRental.delivery_price} €)</p>
                    )}
                  </div>
                )}
                
                {/* Afficher l'option de lavage si activée */}
                {selectedRental.washing_option && (
                  <div className="washing-option-details">
                    <p><strong>Forfait lavage:</strong> Oui (20.00 €)</p>
                  </div>
                )}
              </div>
              
              <h3>Articles loués</h3>
              <table className="details-items-table">
                <thead>
                  <tr>
                    <th>Désignation</th>
                    <th>Quantité</th>
                    <th>Prix unitaire/jour</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRental.items.map((item, index) => {
                    const days = Math.ceil(Math.abs(new Date(selectedRental.end_date) - new Date(selectedRental.start_date)) / (1000 * 60 * 60 * 24)) + 1;
                    const itemTotal = days * item.quantity * item.daily_price;
                    return (
                      <tr key={index}>
                        <td>{item.product_name}</td>
                        <td className="text-center">{item.quantity}</td>
                        <td className="text-right">{item.daily_price.toFixed(2)} €</td>
                        <td className="text-right">{itemTotal.toFixed(2)} €</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              
              <div className="price-summary">
                <p><strong>Total HT:</strong> {(selectedRental.total_price / (1 + (companyInfo.tvaRate/100))).toFixed(2)} €</p>
                <p><strong>TVA ({companyInfo.tvaRate}%):</strong> {(selectedRental.total_price - (selectedRental.total_price / (1 + (companyInfo.tvaRate/100)))).toFixed(2)} €</p>
                <p><strong>Total TTC:</strong> {selectedRental.total_price} €</p>
              </div>
              
              {selectedRental.status.toLowerCase() === 'refused' && (
                <div className="refusal-details">
                  <h3>Motif du refus</h3>
                  <p>{selectedRental.refusal_message || "Aucun motif spécifié"}</p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              {getAvailableActions(selectedRental.status).canSendQuote && (
                <button onClick={() => openQuoteModal(selectedRental)} className="quote-btn">Préparer un devis</button>
              )}
              {getAvailableActions(selectedRental.status).canRefuse && (
                <button onClick={() => {
                  setIsRefusalModalOpen(true);
                  closeDetailsModal();
                }} className="refuse-btn">Refuser la demande</button>
              )}
              {getAvailableActions(selectedRental.status).canSendInvoice && (
                <button onClick={() => openInvoiceModal(selectedRental)} className="invoice-btn">Générer une facture</button>
              )}
              <button onClick={closeDetailsModal}>Fermer</button>
            </div>
          </div>
        </div>
      )}

      {/* Modale de refus */}
      {isRefusalModalOpen && selectedRental && (
        <div className="modal-overlay" onClick={closeRefusalModal}>
          <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Refuser la location #{selectedRental.id}</h2>
              <button onClick={closeRefusalModal}>×</button>
            </div>
            <div className="modal-content">
              <div className="refusal-info">
                <h3>Client</h3>
                <p><strong>Nom:</strong> {selectedRental.first_name} {selectedRental.last_name}</p>
                <p><strong>Email:</strong> {selectedRental.email}</p>
                
                <h3>Détails de la location</h3>
                <p><strong>Période:</strong> {formatDate(selectedRental.start_date)} - {formatDate(selectedRental.end_date)}</p>
                <p><strong>Articles:</strong> {selectedRental.items.length} article(s)</p>
              </div>
              
              <div className="refusal-message">
                <label htmlFor="refusal-reason">
                  <strong>Motif du refus</strong>
                  <span className="required"> (obligatoire)</span>
                </label>
                <p className="help-text">Ce message sera envoyé par email au client pour expliquer le refus de sa demande de location.</p>
                <textarea 
                  id="refusal-reason"
                  value={refusalMessage}
                  onChange={(e) => setRefusalMessage(e.target.value)}
                  placeholder="Veuillez expliquer la raison du refus..."
                  rows={5}
                  required
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button 
                onClick={() => {
                  if (refusalMessage.trim() === '') {
                    alert("Veuillez indiquer un motif de refus.");
                    return;
                  }
                  handleRefuseAction(selectedRental.id, refusalMessage);
                }}
                className="refuse-confirm-btn"
                disabled={refusalMessage.trim() === ''}
              >
                Confirmer le refus
              </button>
              <button onClick={closeRefusalModal} className="cancel-btn">Annuler</button>
            </div>
          </div>
        </div>
      )}
      
      {/* Modale de devis améliorée */}
      {isQuoteModalOpen && quoteData && (
        <div className="modal-overlay" onClick={closeQuoteModal}>
          <div className="modal quote-modal" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Devis N°{quoteData.quoteNumber}</h2>
              <button onClick={closeQuoteModal}>×</button>
            </div>
            
            <div className="modal-content">
              {/* Options d'édition */}
              {isEditMode && (
  <div className="edit-options">
    <h4>Options supplémentaires</h4>
    <label className="option-checkbox">
      <input
        type="checkbox"
        checked={quoteData.washingOption}
        onChange={handleWashingOptionChange}
      />
      <div className="option-label">
        <div className="option-title">Forfait lavage</div>
        <p className="option-description">Nettoyage complet du matériel après location</p>
      </div>
      <div className="option-price">20,00 €</div>
    </label>
  </div>
)}
              
              {/* Prévisualisation du devis (ce qui sera exporté en PDF) */}
              <div ref={quoteRef} className="quote-preview">
                <div className="quote-header-section">
                  <div className="quote-company-info">
                    <img src={companyLogo} alt="Logo" className="company-logo" />
                    <div className="company-details">
                      <h3>{companyInfo.name}</h3>
                      <p>{companyInfo.address}</p>
                      <p>{companyInfo.zipCode} {companyInfo.city}</p>
                      <p>Tél: {companyInfo.phone}</p>
                      <p>Email: {companyInfo.email}</p>
                      <p>SIRET: {companyInfo.siret}</p>
                    </div>
                  </div>
                  
                  <div className="quote-reference">
                    <h2>DEVIS</h2>
                    <p><strong>N°:</strong> {quoteData.quoteNumber}</p>
                    <p><strong>Date:</strong> {new Date().toLocaleDateString('fr-FR')}</p>
                    <p><strong>Validité:</strong> 30 jours</p>
                  </div>
                </div>
                
                <div className="quote-client-info">
                  <h3>Client</h3>
                  <p><strong>Nom:</strong> {quoteData.clientName}</p>
                  <p><strong>Email:</strong> {quoteData.email}</p>
                  
                  {quoteData.deliveryMethod === 'delivery' && quoteData.deliveryAddress && (
                    <div>
                      <p><strong>Adresse de livraison:</strong></p>
                      <p>{quoteData.deliveryAddress.street}</p>
                      <p>{quoteData.deliveryAddress.zipCode} {quoteData.deliveryAddress.city}</p>
                    </div>
                  )}
                </div>
                
                <div className="quote-details">
                  <h3>Détails du devis</h3>
                  <p><strong>Période de location:</strong> {formatDate(quoteData.startDate)} au {formatDate(quoteData.endDate)}</p>
                  <p><strong>Durée:</strong> {quoteData.days} jour{quoteData.days > 1 ? 's' : ''}</p>
                  
                  <table className="quote-items-table">
                    <thead>
                      <tr>
                        <th>Désignation</th>
                        <th>Quantité</th>
                        <th>Prix unitaire/jour</th>
                        <th>Total HT</th>
                        <th>TVA ({companyInfo.tvaRate}%)</th>
                        <th>Total TTC</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quoteData.items.map((item, index) => {
                        // Calculs pour chaque élément
                        const itemTvaAmount = item.subtotal * (companyInfo.tvaRate / 100);
                        const itemTotalTTC = item.subtotal + itemTvaAmount;
                        
                        return (
                          <tr key={index}>
                            <td>{item.product_name}</td>
                            <td className="text-center">
                              {isEditMode ? (
                                <input 
                                  type="number" 
                                  min="1" 
                                  value={item.quantity} 
                                  onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                />
                              ) : (
                                item.quantity
                              )}
                            </td>
                            <td className="text-right">
                              {isEditMode ? (
                                <input 
                                  type="number" 
                                  step="0.01" 
                                  min="0" 
                                  value={item.daily_price} 
                                  onChange={(e) => handleItemChange(index, 'daily_price', e.target.value)}
                                />
                              ) : (
                                `${item.daily_price.toFixed(2)} €`
                              )}
                            </td>
                            <td className="text-right">{item.subtotal.toFixed(2)} €</td>
                            <td className="text-right">{itemTvaAmount.toFixed(2)} €</td>
                            <td className="text-right">{itemTotalTTC.toFixed(2)} €</td>
                          </tr>
                        );
                      })}
                      
                      {/* Ligne pour la livraison si applicable (aller-retour) */}
                      {quoteData.deliveryMethod === 'delivery' && (
                        <tr className="delivery-row">
                          <td>
                            {isEditMode ? (
                              <div className="delivery-edit-inline">
                                Frais de livraison aller-retour (
                                <input 
                                  type="number" 
                                  min="0" 
                                  step="1" 
                                  value={deliveryDistance} 
                                  onChange={(e) => {
                                    const newDistance = parseFloat(e.target.value);
                                    setDeliveryDistance(newDistance);
                                    // Calculer le prix unitaire actuel (ou utiliser la valeur par défaut 1.5)
                                    const pricePerKm = deliveryDistance > 0 ? deliveryPrice / (deliveryDistance * 2) : 1.5;
                                    // Recalculer le prix de livraison avec le prix unitaire actuel
                                    const newDeliveryPrice = newDistance * 2 * pricePerKm;
                                    setDeliveryPrice(newDeliveryPrice);
                                    
                                    // Mettre à jour le total avec le nouveau prix de livraison et l'option lavage
                                    const totalHT = quoteData.subtotal + newDeliveryPrice + (quoteData.washingOption ? 20 : 0);
                                    const totalTVA = totalHT * (companyInfo.tvaRate / 100);
                                    const totalTTC = totalHT + totalTVA;
                                    
                                    setQuoteData({
                                      ...quoteData,
                                      deliveryPrice: newDeliveryPrice,
                                      totalPrice: totalTTC
                                    });
                                  }}
                                  className="small-input"
                                /> km)
                                {loading2 && <span className="spinner-inline"></span>}
                              </div>
                            ) : (
                              `Frais de livraison aller-retour (${deliveryDistance} km)`
                            )}
                          </td>
                          <td className="text-center">2</td>
                          <td className="text-right">
                            {isEditMode ? (
                              <input 
                                type="number" 
                                step="0.01" 
                                min="0" 
                                value={(deliveryDistance > 0 ? deliveryPrice / (deliveryDistance * 2) : 1.5).toFixed(2)} 
                                onChange={(e) => {
                                  const pricePerKm = parseFloat(e.target.value);
                                  const newDeliveryPrice = deliveryDistance * 2 * pricePerKm;
                                  
                                  setDeliveryPrice(newDeliveryPrice);
                                  
                                  // Mettre à jour le total avec le nouveau prix de livraison
                                  const totalHT = quoteData.subtotal + newDeliveryPrice + (quoteData.washingOption ? 20 : 0);
                                  const totalTVA = totalHT * (companyInfo.tvaRate / 100);
                                  const totalTTC = totalHT + totalTVA;
                                  
                                  setQuoteData({
                                    ...quoteData,
                                    deliveryPrice: newDeliveryPrice,
                                    totalPrice: totalTTC
                                  });
                                }}
                                className="small-input"
                              />
                            ) : (
                              `${(deliveryDistance > 0 ? deliveryPrice / (deliveryDistance * 2) : 1.5).toFixed(2)} €`
                            )}
                          </td>
                          <td className="text-right">{deliveryPrice.toFixed(2)} €</td>
                          <td className="text-right">{deliveryPrice.toFixed(2)} €</td>
                          <td className="text-right">{(deliveryPrice * companyInfo.tvaRate / 100).toFixed(2)} €</td>
                          <td className="text-right">{(deliveryPrice * (1 + companyInfo.tvaRate / 100)).toFixed(2)} €</td>
                        </tr>
                      )}
                      
                      {/* Nouvelle ligne pour le forfait lavage si activé */}
                      {quoteData.washingOption && (
                        <tr className="washing-option-row">
                          <td>Forfait lavage</td>
                          <td className="text-center">1</td>
                          <td className="text-right">20.00 €</td>
                          <td className="text-right">20.00 €</td>
                          <td className="text-right">{(20 * companyInfo.tvaRate / 100).toFixed(2)} €</td>
                          <td className="text-right">{(20 * (1 + companyInfo.tvaRate / 100)).toFixed(2)} €</td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3" className="text-right">Sous-total HT</td>
                        <td className="text-right">
                          {(quoteData.subtotal + deliveryPrice + (quoteData.washingOption ? 20 : 0)).toFixed(2)} €
                        </td>
                        <td colSpan="2"></td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="text-right">TVA ({companyInfo.tvaRate}%)</td>
                        <td colSpan="1"></td>
                        <td className="text-right">
                          {((quoteData.subtotal + deliveryPrice + (quoteData.washingOption ? 20 : 0)) * companyInfo.tvaRate / 100).toFixed(2)} €
                        </td>
                        <td colSpan="1"></td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan="5" className="text-right">Total TTC</td>
                        <td className="text-right">
                          {((quoteData.subtotal + deliveryPrice + (quoteData.washingOption ? 20 : 0)) * (1 + companyInfo.tvaRate / 100)).toFixed(2)} €
                        </td>
                      </tr>
                      <tr className="deposit-row">
                        <td colSpan="5" className="text-right">Acompte à payer (50%)</td>
                        <td className="text-right">
                          {(((quoteData.subtotal + deliveryPrice + (quoteData.washingOption ? 20 : 0)) * (1 + companyInfo.tvaRate / 100)) * 0.5).toFixed(2)} €
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                
                <div className="quote-terms">
                  <h3>Conditions</h3>
                  <p>Ce devis est valable 30 jours à compter de sa date d'émission.</p>
                  <p>Un acompte de 50% du montant total est demandé pour confirmer la commande.</p>
                  <p>Le solde devra être réglé à la livraison du matériel.</p>
                </div>
              </div>
            </div>
            
            <div className="modal-footer">
              {isEditMode ? (
                <>
                  <button onClick={handleUpdateQuote} className="save-btn">Enregistrer les modifications</button>
                  <button onClick={() => setIsEditMode(false)} className="cancel-btn">Annuler</button>
                </>
              ) : (
                <>
                  <button onClick={() => setIsEditMode(true)} className="edit-btn">Modifier</button>
                  <button onClick={handleDownloadQuote} className="download-btn">Télécharger PDF</button>
                  <button onClick={() => handleGenerateAndSendQuote(quoteData.id)} className="send-btn">Envoyer par email</button>
                  <button onClick={closeQuoteModal} className="close-btn">Fermer</button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Modale de facture */}
      {isInvoiceModalOpen && invoiceData && (
        <div className="modal-overlay" onClick={closeInvoiceModal}>
          <div className="modal invoice-modal" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Facture N°{invoiceData.invoiceNumber}</h2>
              <button onClick={closeInvoiceModal}>×</button>
            </div>
            
            <div className="modal-content">
              {/* Prévisualisation de la facture */}
              <div ref={invoiceRef} className="invoice-preview">
                <div className="invoice-header-section">
                  <div className="invoice-company-info">
                    <img src={companyLogo} alt="Logo" className="company-logo" />
                    <div className="company-details">
                      <h3>{companyInfo.name}</h3>
                      <p>{companyInfo.address}</p>
                      <p>{companyInfo.zipCode} {companyInfo.city}</p>
                      <p>Tél: {companyInfo.phone}</p>
                      <p>Email: {companyInfo.email}</p>
                      <p>SIRET: {companyInfo.siret}</p>
                    </div>
                  </div>
                  
                  <div className="invoice-reference">
                    <h2>FACTURE</h2>
                    <p><strong>N°:</strong> {invoiceData.invoiceNumber}</p>
                    <p><strong>Date:</strong> {new Date().toLocaleDateString('fr-FR')}</p>
                  </div>
                </div>
                
                <div className="invoice-client-info">
                  <h3>Client</h3>
                  <p><strong>Nom:</strong> {invoiceData.clientName}</p>
                  <p><strong>Email:</strong> {invoiceData.email}</p>
                  
                  {invoiceData.deliveryMethod === 'delivery' && invoiceData.deliveryAddress && (
                    <div>
                      <p><strong>Adresse de livraison:</strong></p>
                      <p>{invoiceData.deliveryAddress.street}</p>
                      <p>{invoiceData.deliveryAddress.zipCode} {invoiceData.deliveryAddress.city}</p>
                    </div>
                  )}
                </div>
                
                <div className="invoice-details">
                  <h3>Détails de la facture</h3>
                  <p><strong>Période de location:</strong> {formatDate(invoiceData.startDate)} au {formatDate(invoiceData.endDate)}</p>
                  <p><strong>Durée:</strong> {invoiceData.days} jour{invoiceData.days > 1 ? 's' : ''}</p>
                  
                  <table className="invoice-items-table">
                    <thead>
                      <tr>
                        <th style={{color: '#000000'}}>Désignation</th>
                        <th style={{color: '#000000'}}>Quantité</th>
                        <th style={{color: '#000000'}}>Prix unitaire/jour</th>
                        <th style={{color: '#000000'}}>Total HT</th>
                        <th style={{color: '#000000'}}>TVA ({companyInfo.tvaRate}%)</th>
                        <th style={{color: '#000000'}}>Total TTC</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData.items.map((item, index) => {
                        // Calculs pour chaque élément
                        const itemTvaAmount = item.subtotal * (companyInfo.tvaRate / 100);
                        const itemTotalTTC = item.subtotal + itemTvaAmount;
                        
                        return (
                          <tr key={index}>
                            <td>{item.product_name}</td>
                            <td className="text-center">{item.quantity}</td>
                            <td className="text-right">{item.daily_price.toFixed(2)} €</td>
                            <td className="text-right">{item.subtotal.toFixed(2)} €</td>
                            <td className="text-right">{itemTvaAmount.toFixed(2)} €</td>
                            <td className="text-right">{itemTotalTTC.toFixed(2)} €</td>
                          </tr>
                        );
                      })}
                      
                      {/* Ligne pour la livraison si applicable */}
                      {invoiceData.deliveryMethod === 'delivery' && (
                        <tr className="delivery-row">
                          <td>Frais de livraison aller-retour ({invoiceData.deliveryDistance} km)</td>
                          <td className="text-center">2</td>
                          <td className="text-right">{(invoiceData.deliveryPrice/(invoiceData.deliveryDistance * 2)).toFixed(2)} €</td>
                          <td className="text-right">{invoiceData.deliveryPrice.toFixed(2)} €</td>
                          <td className="text-right">{(invoiceData.deliveryPrice * companyInfo.tvaRate / 100).toFixed(2)} €</td>
                          <td className="text-right">{(invoiceData.deliveryPrice * (1 + companyInfo.tvaRate / 100)).toFixed(2)} €</td>
                        </tr>
                      )}
                      
                      {/* Ligne pour le forfait lavage si activé */}
                      {invoiceData.washingOption && (
                        <tr className="washing-option-row">
                          <td>Forfait lavage</td>
                          <td className="text-center">1</td>
                          <td className="text-right">20.00 €</td>
                          <td className="text-right">20.00 €</td>
                          <td className="text-right">{(20 * companyInfo.tvaRate / 100).toFixed(2)} €</td>
                          <td className="text-right">{(20 * (1 + companyInfo.tvaRate / 100)).toFixed(2)} €</td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3" className="text-right">Sous-total HT</td>
                        <td className="text-right">
                          {(invoiceData.subtotal + invoiceData.deliveryPrice + (invoiceData.washingOption ? 20 : 0)).toFixed(2)} €
                        </td>
                        <td colSpan="2"></td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="text-right">TVA ({companyInfo.tvaRate}%)</td>
                        <td colSpan="1"></td>
                        <td className="text-right">{invoiceData.tvaAmount.toFixed(2)} €</td>
                        <td colSpan="1"></td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan="5" className="text-right">Total TTC</td>
                        <td className="text-right">{invoiceData.totalPrice.toFixed(2)} €</td>
                      </tr>
                      {/* Ligne pour l'acompte déjà payé */}
                      <tr className="paid-deposit-row">
                        <td colSpan="5" className="text-right">Acompte déjà payé (50%)</td>
                        <td className="text-right">-{(invoiceData.totalPrice * 0.5).toFixed(2)} €</td>
                      </tr>
                      {/* Ligne pour le solde restant à payer */}
                      <tr className="remaining-amount-row">
                        <td colSpan="5" className="text-right"><strong>Reste à payer</strong></td>
                        <td className="text-right"><strong>{(invoiceData.totalPrice * 0.5).toFixed(2)} €</strong></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                
                <div className="invoice-terms">
                  <h3>Informations de paiement</h3>
                  <p>Un acompte de 50% ({(invoiceData.totalPrice * 0.5).toFixed(2)} €) a été versé.</p>
                  <p>Le solde de {(invoiceData.totalPrice * 0.5).toFixed(2)} € est à régler lors de la récupération du matériel.</p>
                  <p>Merci pour votre confiance.</p>
                </div>
              </div>
            </div>
            
            <div className="modal-footer">
              <button onClick={handleDownloadInvoice} className="download-btn">Télécharger PDF</button>
              <button onClick={() => handleGenerateAndSendInvoice(invoiceData.id)} className="send-btn">Envoyer par email</button>
              <button onClick={closeInvoiceModal} className="close-btn">Fermer</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminRentalsPage;