// pages/ClientsPage.js
import React, { useState, useEffect } from 'react';
import './ClientsPage.css';
import { useNavigate } from 'react-router-dom';

function ClientsPage() {
  const navigate = useNavigate();

  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    phone: '',
    street: '',
    city: '',
    zip_code: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://backend.ouest-pro-event.fr/admin/accounts');
      if (!response.ok) {
        throw new Error('Erreur lors du chargement des clients');
      }
      const data = await response.json();
      setClients(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Erreur lors du chargement des clients:', error);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    // Vérification email
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      setError('Adresse email invalide');
      return false;
    }

    // Vérification mot de passe (seulement pour la création de compte)
    if (!showEditForm) {
      if (formData.password.length < 8) {
        setError('Le mot de passe doit contenir au moins 8 caractères');
        return false;
      }

      if (!/[A-Z]/.test(formData.password)) {
        setError('Le mot de passe doit contenir au moins une majuscule');
        return false;
      }

      if (!/\d/.test(formData.password)) {
        setError('Le mot de passe doit contenir au moins un chiffre');
        return false;
      }

      if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)) {
        setError('Le mot de passe doit contenir au moins un caractère spécial');
        return false;
      }

      // Vérification correspondance des mots de passe
      if (formData.password !== formData.confirm_password) {
        setError('Les mots de passe ne correspondent pas');
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!validateForm()) {
      return;
    }

    try {
      const url = showEditForm 
        ? `https://backend.ouest-pro-event.fr/admin/accounts/${currentClient.id}`
        : 'https://backend.ouest-pro-event.fr/admin/accounts/create';
      
      const method = showEditForm ? 'PUT' : 'POST';
      
      // Si c'est une modification, on n'envoie pas les champs de mot de passe
      const dataToSend = showEditForm 
        ? { 
            email: formData.email,
            first_name: formData.first_name,
            last_name: formData.last_name,
            phone: formData.phone,
            street: formData.street,
            city: formData.city,
            zip_code: formData.zip_code
          }
        : formData;
      
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erreur lors de l\'opération');
      }

      const data = await response.json();
      setSuccess(showEditForm ? 'Compte client modifié avec succès' : 'Compte client créé avec succès');
      
      // Réinitialiser le formulaire
      setFormData({
        email: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: '',
        phone: '',
        street: '',
        city: '',
        zip_code: ''
      });
      
      fetchClients();
      
      // Fermer le formulaire après création/modification réussie
      setShowCreateForm(false);
      setShowEditForm(false);
      setCurrentClient(null);
      
    } catch (error) {
      console.error('Erreur lors de l\'opération:', error);
      setError(error.message || 'Une erreur est survenue');
    }
  };

  const handleEdit = (client) => {
    setCurrentClient(client);
    setFormData({
      email: client.email,
      first_name: client.first_name || '',
      last_name: client.last_name || '',
      phone: client.phone || '',
      street: client.street || '',
      city: client.city || '',
      zip_code: client.zip_code || '',
      password: '',
      confirm_password: ''
    });
    setShowEditForm(true);
    setShowCreateForm(false);
    setError('');
    setSuccess('');
  };

  const handleCreateQuote = (client) => {
    // Naviguer vers la page de création de devis avec les informations du client
    navigate(`/create-quote`, { 
      state: { 
        clientInfo: {
          id: client.id,
          first_name: client.first_name,
          last_name: client.last_name,
          email: client.email,
          phone: client.phone,
          street: client.street,
          city: client.city,
          zip_code: client.zip_code
        }
      } 
    });
  };

  return (
    <div className="clients-page">
      <div className="clients-header">
        <h1>Gestion des Clients</h1>
        <button 
          className="create-button" 
          onClick={() => {
            setShowCreateForm(!showCreateForm);
            setShowEditForm(false);
            setCurrentClient(null);
            setFormData({
              email: '',
              password: '',
              confirm_password: '',
              first_name: '',
              last_name: '',
              phone: '',
              street: '',
              city: '',
              zip_code: ''
            });
            setError('');
            setSuccess('');
          }}
        >
          {showCreateForm ? 'Annuler' : 'Créer un compte client'}
        </button>
      </div>

      {(showCreateForm || showEditForm) && (
        <div className="create-client-form">
          <h2>{showEditForm ? 'Modifier le compte client' : 'Nouveau compte client'}</h2>
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="first_name">Prénom</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Nom</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="phone">Téléphone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            
            {/* Nouveaux champs d'adresse */}
            <div className="form-group">
              <label htmlFor="street">Adresse</label>
              <input
                type="text"
                id="street"
                name="street"
                value={formData.street}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="zip_code">Code postal</label>
                <input
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  value={formData.zip_code}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">Ville</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            
            {/* Afficher les champs de mot de passe uniquement lors de la création */}
            {!showEditForm && (
              <>
                <div className="form-group">
                  <label htmlFor="password">Mot de passe *</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                  <small>
                    Le mot de passe doit contenir au moins 8 caractères, une majuscule, 
                    un chiffre et un caractère spécial.
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="confirm_password">Confirmer le mot de passe *</label>
                  <input
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    value={formData.confirm_password}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </>
            )}
            
            <div className="form-actions">
              <button type="submit" className="submit-button">
                {showEditForm ? 'Enregistrer les modifications' : 'Créer le compte'}
              </button>
              <button 
                type="button" 
                className="cancel-button"
                onClick={() => {
                  setShowCreateForm(false);
                  setShowEditForm(false);
                  setCurrentClient(null);
                }}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="clients-list">
        <h2>Liste des clients</h2>
        {isLoading ? (
          <div className="loading">Chargement des clients...</div>
        ) : clients.length === 0 ? (
          <div className="no-clients">Aucun client trouvé</div>
        ) : (
          <table className="clients-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Adresse</th>
                <th>Date de création</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {clients.map(client => (
                <tr key={client.id}>
                  <td>{client.id}</td>
                  <td>{client.last_name || '-'}</td>
                  <td>{client.first_name || '-'}</td>
                  <td>{client.email}</td>
                  <td>{client.phone || '-'}</td>
                  <td>
                    {client.street ? 
                      `${client.street}, ${client.zip_code || ''} ${client.city || ''}` : 
                      '-'}
                  </td>
                  <td>{new Date(client.created_at).toLocaleDateString()}</td>
                  <td className="actions-cell">
                    <button className="edit-button" onClick={() => handleEdit(client)}>
                      Modifier
                    </button>
                    <button 
                      className="quote-button"
                      onClick={() => handleCreateQuote(client)}
                    >
                      Créer un devis
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default ClientsPage;