import React, { useState, useEffect } from 'react';
import './ProductsPage.css';
import CriticalDatesCalendar from '../components/CriticalDatesCalendar';

function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);

  const [name, setName] = useState('');
  const [stock, setStock] = useState(0);
  const [dailyPrice, setDailyPrice] = useState(0);
  
  // Garder la sélection simple d'une catégorie pour la création/édition
  const [selectedCategory, setSelectedCategory] = useState("");

  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [photoPreviews, setPhotoPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [identifier, setIdentifier] = useState('');
  const [description, setDescription] = useState('');

  // Toutes les catégories disponibles
  const [allCategories, setAllCategories] = useState([]);
  
  // Nouvel état pour filtrer les produits par catégorie
  const [categoryFilter, setCategoryFilter] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Récupérer les produits depuis le backend
  const fetchProducts = async () => {
    try {
      const res = await fetch('https://backend.ouest-pro-event.fr/admin/products');
      if (!res.ok) {
        throw new Error('Erreur lors de la récupération des produits');
      }
      const data = await res.json();
      setProducts(data);
      setFilteredProducts(data); // Initialiser avec tous les produits
    } catch (error) {
      setError(error.message);
    }
  };

  // Charger les catégories depuis le backend
  const fetchCategories = async () => {
    try {
      const res = await fetch('https://backend.ouest-pro-event.fr/admin/categories');
      if (!res.ok) {
        throw new Error('Erreur lors de la récupération des catégories');
      }
      const data = await res.json();
      setAllCategories(data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  // Nouvelle fonction pour filtrer les produits par catégorie
  const handleFilterChange = (e) => {
    const categoryId = e.target.value;
    setCategoryFilter(categoryId);
    
    if (!categoryId) {
      // Si aucune catégorie n'est sélectionnée, afficher tous les produits
      setFilteredProducts(products);
    } else {
      // Filtrer les produits par catégorie
      const filtered = products.filter(product => {
        // Vérifier si le produit appartient à la catégorie sélectionnée
        return product.categoryPaths.some(path => {
          // Rechercher le nom de la catégorie filtré dans les chemins de catégories du produit
          const category = allCategories.find(c => c.id.toString() === categoryId);
          return category && path.includes(category.name);
        });
      });
      setFilteredProducts(filtered);
    }
  };

  // Gestion des photos (identique à votre version)
  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];
    const previews = [];

    files.forEach(file => {
      if (!file.type.startsWith('image/')) {
        alert(`Le fichier ${file.name} n'est pas une image valide.`);
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        alert(`Le fichier ${file.name} dépasse la taille maximale de 5MB.`);
        return;
      }
      const isDuplicate = selectedPhotos.some(
        selectedFile => selectedFile.name === file.name && selectedFile.size === file.size
      );
      if (isDuplicate) {
        alert(`Le fichier ${file.name} est déjà sélectionné.`);
        return;
      }
      validFiles.push(file);
      previews.push(URL.createObjectURL(file));
    });

    setSelectedPhotos(prev => [...prev, ...validFiles]);
    setPhotoPreviews(prev => [...prev, ...previews]);
  };

  useEffect(() => {
    return () => {
      photoPreviews.forEach(src => URL.revokeObjectURL(src));
    };
  }, [photoPreviews]);

  // Création/Mise à jour de produit
  const handleCreate = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const payload = {
        name,
        identifier,
        description,
        stock,
        dailyPrice,
        // On envoie un chemin unique composé de la catégorie sélectionnée
        categoryPaths: selectedCategory ? [[selectedCategory]] : []
      };

      const method = editingProduct ? 'PUT' : 'POST';
      const url = editingProduct
        ? `https://backend.ouest-pro-event.fr/admin/products/${editingProduct.id}`
        : `https://backend.ouest-pro-event.fr/admin/products`;

      const res = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de l'opération sur le produit");
      }

      const resData = await res.json();
      const productId = editingProduct ? editingProduct.id : resData.id;

      // Téléchargement des photos si présentes
      if (selectedPhotos.length > 0) {
        const formData = new FormData();
        selectedPhotos.forEach(photo => formData.append('photos', photo));

        const photoRes = await fetch(`https://backend.ouest-pro-event.fr/admin/products/${productId}/photos`, {
          method: 'POST',
          body: formData
        });

        if (!photoRes.ok) {
          const errData = await photoRes.json();
          throw new Error(errData.error || "Erreur lors du téléchargement des photos");
        }
      }

      alert(editingProduct ? "Produit mis à jour avec succès" : "Produit créé avec succès");
      // Réinitialiser le formulaire
      setName('');
      setStock(0);
      setDailyPrice(0);
      setSelectedCategory("");
      setSelectedPhotos([]);
      setPhotoPreviews([]);
      setIdentifier('');
      setDescription('');
      setEditingProduct(null);
      fetchProducts();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour éditer un produit
  const handleEdit = async (product) => {
    setIsLoading(true);
    setError(null);
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/products/${product.id}`);
      if (!res.ok) {
        throw new Error('Erreur lors de la récupération des détails du produit');
      }
      const detailedProduct = await res.json();
      setEditingProduct(detailedProduct);
      setName(detailedProduct.name);
      setStock(detailedProduct.stock);
      setDailyPrice(detailedProduct.dailyPrice);
      setIdentifier(detailedProduct.identifier || '');
      setDescription(detailedProduct.description || '');
      // On prend le premier élément du chemin de catégorie
      if (
        detailedProduct.categoryPaths &&
        detailedProduct.categoryPaths.length > 0 &&
        detailedProduct.categoryPaths[0].length > 0
      ) {
        // Trouver l'ID de la catégorie à partir de son nom
        const categoryName = detailedProduct.categoryPaths[0][0];
        const category = allCategories.find(c => c.name === categoryName);
        setSelectedCategory(category ? category.id : "");
      } else {
        setSelectedCategory("");
      }
      setPhotoPreviews([]);
      setSelectedPhotos([]);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Suppression d'un produit
  const handleDelete = async (productId) => {
    if (!window.confirm("Voulez-vous vraiment supprimer ce produit ?")) return;
    setIsLoading(true);
    setError(null);
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/products/${productId}`, {
        method: 'DELETE'
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de la suppression du produit");
      }
      alert("Produit supprimé avec succès");
      fetchProducts();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Suppression d'une photo existante
  const handleDeletePhoto = async (photoId) => {
    if (!window.confirm("Voulez-vous vraiment supprimer cette photo ?")) return;
    setIsLoading(true);
    setError(null);
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/products/${editingProduct.id}/photos/${photoId}`, {
        method: 'DELETE'
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de la suppression de la photo");
      }
      alert("Photo supprimée avec succès");
      handleEdit(editingProduct);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Suppression d'une photo sélectionnée avant téléchargement
  const handleRemoveSelectedPhoto = (index) => {
    const updatedPhotos = [...selectedPhotos];
    const updatedPreviews = [...photoPreviews];
    updatedPhotos.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setSelectedPhotos(updatedPhotos);
    setPhotoPreviews(updatedPreviews);
  };

  return (
    <div className="products-page">
      <header className="page-header">
        <h1>Gestion des Produits</h1>
        {error && <div className="error-alert">{error}</div>}
      </header>

      <div className="main-content">
        <section className="product-form-section">
          <div className="card">
            <div className="card-header">
              <h2>{editingProduct ? "Modifier le produit" : "Ajouter un nouveau produit"}</h2>
            </div>
            <div className="card-body">
              {isLoading && <div className="loader-overlay"><div className="loader"></div></div>}
              <form onSubmit={(e) => { e.preventDefault(); handleCreate(); }}>
                <div className="form-grid">
                  <div className="form-group">
                    <label htmlFor="productIdentifier">Identifiant (optionnel)</label>
                    <input
                      id="productIdentifier"
                      type="text"
                      value={identifier}
                      onChange={e => setIdentifier(e.target.value)}
                      placeholder="Ex: REF-001"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="productName">Nom du produit</label>
                    <input
                      id="productName"
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      placeholder="Ex: Table rectangulaire"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="productDescription">Description</label>
                    <textarea
                      id="productDescription"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                      placeholder="Description du produit..."
                      rows={4}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="productStock">Stock disponible</label>
                    <input
                      id="productStock"
                      type="number"
                      value={stock}
                      onChange={e => setStock(parseInt(e.target.value))}
                      min="0"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="productPrice">Prix journalier (€)</label>
                    <input
                      id="productPrice"
                      type="number"
                      value={dailyPrice}
                      onChange={e => setDailyPrice(parseFloat(e.target.value))}
                      min="0"
                      step="0.01"
                      required
                    />
                  </div>

                  {/* Sélection simple de la catégorie */}
                  <div className="form-group">
                    <label htmlFor="productCategory">Catégorie</label>
                    <select
                      id="productCategory"
                      value={selectedCategory || ""}
                      onChange={e => setSelectedCategory(e.target.value)}
                    >
                      <option value="">-- Sélectionner une catégorie --</option>
                      {allCategories.map(cat => (
                        <option key={cat.id} value={cat.id}>
                          {cat.full_path || cat.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="photos-section">
                  <h3>Photos du produit</h3>
                  <div className="photos-upload">
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handlePhotoChange}
                      className="file-input"
                    />
                    <small className="help-text">
                      Formats acceptés: JPG, PNG, GIF. Taille max: 5MB par image
                    </small>
                  </div>

                  {photoPreviews.length > 0 && (
                    <div className="photos-preview">
                      <h4>Nouvelles photos</h4>
                      <div className="photos-grid">
                        {photoPreviews.map((src, index) => (
                          <div key={index} className="photo-item">
                            <img src={src} alt={`Aperçu ${index + 1}`} />
                            <button
                              type="button"
                              className="btn-icon delete"
                              onClick={() => handleRemoveSelectedPhoto(index)}
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {editingProduct?.photos?.length > 0 && (
                    <div className="current-photos">
                      <h4>Photos actuelles</h4>
                      <div className="photos-grid">
                        {editingProduct.photos.map(photo => (
                          <div key={photo.id} className="photo-item">
                            <img 
                              src={`https://backend.ouest-pro-event.fr${photo.url}`}
                              alt="Photo produit"
                            />
                            <button
                              type="button"
                              className="btn-icon delete"
                              onClick={() => handleDeletePhoto(photo.id)}
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-actions">
                  <button type="submit" className="btn btn-success">
                    {editingProduct ? "Enregistrer les modifications" : "Créer le produit"}
                  </button>
                  {editingProduct && (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setEditingProduct(null);
                        setName('');
                        setStock(0);
                        setDailyPrice(0);
                        setSelectedCategory("");
                        setIdentifier('');
                        setDescription('');
                        setSelectedPhotos([]);
                        setPhotoPreviews([]);
                      }}
                    >
                      Annuler
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>

        <section className="products-list-section">
          <div className="card">
            <div className="card-header">
              <h2>Liste des produits</h2>
              
              {/* Ajout du filtre par catégorie */}
              <div className="category-filter">
                <label htmlFor="categoryFilter">Filtrer par catégorie:</label>
                <select 
                  id="categoryFilter" 
                  value={categoryFilter} 
                  onChange={handleFilterChange}
                >
                  <option value="">Toutes les catégories</option>
                  {allCategories.map(cat => (
                    <option key={cat.id} value={cat.id}>
                      {cat.full_path || cat.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="card-body">
              <div className="products-grid">
                {filteredProducts.map(product => (
                  <div key={product.id} className="product-card">
                    {/* Ici on affiche la photo à côté du nom */}
                    <div className="product-header">
                      {product.identifier && (
                        <span className="product-identifier">Réf: {product.identifier}</span>
                      )}

                      <div className="product-name-with-photo">
                        <h3>{product.name}</h3>
                        {product.photos && product.photos.length > 0 && (
                          <img
                            className="product-thumbnail"
                            src={`https://backend.ouest-pro-event.fr${product.photos[0].url}`}
                            alt={`Photo de ${product.name}`}
                          />
                        )}
                      </div>

                      <div className="product-actions">
                        <button
                          className="btn btn-edit"
                          onClick={() => handleEdit(product)}
                        >
                          Modifier
                        </button>
                        <button
                          className="btn btn-delete"
                          onClick={() => handleDelete(product.id)}
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>

                    {product.description && (
                      <div className="product-description">
                        <p>{product.description}</p>
                      </div>
                    )}

                    {/* Calendrier des dates critiques */}
                    <CriticalDatesCalendar productId={product.id} />

                    <div className="product-details">
                      <div className="product-info">
                        <span className="info-label">Stock:</span>
                        <span className="info-value">{product.stock}</span>
                      </div>
                      <div className="product-info">
                        <span className="info-label">Prix journalier:</span>
                        <span className="info-value">{product.dailyPrice} €</span>
                      </div>
                    </div>

                    {product.categoryPaths?.length > 0 && (
                      <div className="product-categories">
                        <h4>Catégories</h4>
                        <ul>
                          {product.categoryPaths.map((path, idx) => (
                            <li key={idx}>{path.join(" > ")}</li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {/* Vous pouvez garder ce bloc si vous souhaitez afficher toutes les photos
                        en plus de la miniature à côté du nom */}
                    {product.photos?.length > 0 && (
                      <div className="product-photos">
                        <div className="photos-grid">
                          {product.photos.map(photo => (
                            <img
                              key={photo.id}
                              src={`https://backend.ouest-pro-event.fr${photo.url}`}
                              alt={`Photo de ${product.name}`}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ProductsPage;
