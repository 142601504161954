import React, { useState, useEffect } from 'react';
import './CategoriesPage.css';

function CategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  // Pour créer une hiérarchie (une seule catégorie avec nom composite)
  const [newLevels, setNewLevels] = useState([""]);

  // Pour modifier une hiérarchie existante
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [editLevels, setEditLevels] = useState([]);

  // État pour gérer les positions (pour les groupes de catégories de premier niveau)
  const [positions, setPositions] = useState({});

  // Charger les catégories depuis le backend
  const fetchCategories = async () => {
    try {
      const res = await fetch('https://backend.ouest-pro-event.fr/admin/categories');
      if (!res.ok) {
        throw new Error('Erreur lors de la récupération des catégories');
      }
      const data = await res.json();
      setCategories(data);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fonction utilitaire pour regrouper les catégories par leur premier segment (root)
  const getGroupedTopLevelCategories = () => {
    const grouped = {};
    categories.forEach(cat => {
      // Le root est le premier segment de full_path
      const root = cat.full_path.split('/')[0];
      // On conserve le premier enregistrement rencontré pour ce root
      if (!grouped[root]) {
        grouped[root] = cat;
      }
    });
    return Object.values(grouped);
  };

  // Mettre à jour l'état "positions" uniquement pour les groupes de premier niveau
  useEffect(() => {
    const topGroups = getGroupedTopLevelCategories();
    const pos = {};
    topGroups.forEach(cat => {
      pos[cat.id] = cat.position;
    });
    setPositions(pos);
  }, [categories]);

  // --- Création d'une hiérarchie unique ---
  const handleCreateHierarchy = async () => {
    setError(null);
    const hierarchyName = newLevels
      .map(level => level.trim())
      .filter(level => level !== "")
      .join('/');
    if (!hierarchyName) {
      setError("La hiérarchie est vide.");
      return;
    }
    try {
      const res = await fetch('https://backend.ouest-pro-event.fr/admin/categories', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // Créer une catégorie de premier niveau avec le nom composite
        body: JSON.stringify({ name: hierarchyName, parent_id: null })
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de la création de la hiérarchie");
      }
      await res.json();
      alert(`Hiérarchie créée : ${hierarchyName}`);
      setNewLevels([""]);
      fetchCategories();
    } catch (err) {
      setError(err.message);
    }
  };

  // --- Modification d'une hiérarchie existante ---
  const handleEditClick = (cat) => {
    setEditingCategoryId(cat.id);
    const levels = cat.full_path.split('/');
    setEditLevels(levels);
  };

  const handleEditLevelChange = (index, value) => {
    const updated = [...editLevels];
    updated[index] = value;
    setEditLevels(updated);
  };

  const addEditLevel = () => {
    setEditLevels([...editLevels, ""]);
  };

  const removeEditLevel = (index) => {
    const updated = editLevels.filter((_, idx) => idx !== index);
    setEditLevels(updated);
  };

  const handleUpdateHierarchy = async () => {
    setError(null);
    const hierarchyName = editLevels
      .map(level => level.trim())
      .filter(level => level !== "")
      .join('/');
    if (!hierarchyName) {
      setError("La hiérarchie est vide.");
      return;
    }
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/categories/${editingCategoryId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        // Mise à jour avec le nom composite et parent_id null
        body: JSON.stringify({ name: hierarchyName, parent_id: null })
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de la mise à jour de la hiérarchie");
      }
      alert("Hiérarchie mise à jour avec succès");
      setEditingCategoryId(null);
      setEditLevels([]);
      fetchCategories();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCancelEdit = () => {
    setEditingCategoryId(null);
    setEditLevels([]);
  };

  const handleDeleteCategory = async (categoryId) => {
    if (!window.confirm("Voulez-vous vraiment supprimer cette catégorie ?")) return;
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/categories/${categoryId}`, {
        method: 'DELETE'
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de la suppression de la catégorie");
      }
      alert("Catégorie supprimée avec succès");
      fetchCategories();
    } catch (err) {
      setError(err.message);
    }
  };

  // --- Gestion des positions pour les groupes (premier niveau) ---
  const topLevelGroups = getGroupedTopLevelCategories();

  const handlePositionChange = (catId, newPosition) => {
    setPositions(prev => ({ ...prev, [catId]: newPosition }));
  };

  const handleSavePositions = async () => {
    // On prépare le payload pour les groupes de premier niveau
    const payload = topLevelGroups.map(cat => ({
      id: cat.id,
      position: parseInt(positions[cat.id])
    }));
    try {
      const res = await fetch('https://backend.ouest-pro-event.fr/admin/categories/positions', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de la mise à jour des positions");
      }
      alert("Positions mises à jour avec succès");
      fetchCategories();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="categories-page">
      <h1>Gestion des Catégories</h1>
      {error && <div className="error-alert">{error}</div>}

      {/* Formulaire de création d'une hiérarchie */}
      <section className="create-hierarchy">
        <h2>Créer une hiérarchie</h2>
        {newLevels.map((level, idx) => (
          <div key={idx} className="level-input">
            <input
              type="text"
              value={level}
              placeholder={`Niveau ${idx + 1}`}
              onChange={(e) => {
                const updated = [...newLevels];
                updated[idx] = e.target.value;
                setNewLevels(updated);
              }}
            />
            {newLevels.length > 1 && (
              <button type="button" onClick={() => {
                const updated = newLevels.filter((_, i) => i !== idx);
                setNewLevels(updated);
              }}>
                ×
              </button>
            )}
          </div>
        ))}
        <button type="button" onClick={() => setNewLevels([...newLevels, ""])}>
          + Ajouter un niveau
        </button>
        <button type="button" onClick={handleCreateHierarchy}>
          Créer la hiérarchie
        </button>
      </section>

      {/* Liste des catégories existantes */}
      <section className="existing-categories">
        <h2>Catégories existantes</h2>
        <ul>
          {categories.map((cat) => (
            <li key={cat.id}>
              <span>{cat.full_path || cat.name} (Position: {cat.position})</span>
              <button onClick={() => handleEditClick(cat)}>Modifier</button>
              <button onClick={() => handleDeleteCategory(cat.id)}>Supprimer</button>
            </li>
          ))}
        </ul>
      </section>

      {/* Formulaire d'édition d'une hiérarchie */}
      {editingCategoryId && (
        <section className="edit-hierarchy">
          <h2>Modifier la hiérarchie</h2>
          {editLevels.map((level, idx) => (
            <div key={idx} className="level-input">
              <input
                type="text"
                value={level}
                placeholder={`Niveau ${idx + 1}`}
                onChange={(e) => handleEditLevelChange(idx, e.target.value)}
              />
              {editLevels.length > 1 && (
                <button type="button" onClick={() => removeEditLevel(idx)}>×</button>
              )}
            </div>
          ))}
          <button type="button" onClick={addEditLevel}>+ Ajouter un niveau</button>
          <button type="button" onClick={handleUpdateHierarchy}>Sauvegarder la hiérarchie</button>
          <button type="button" onClick={handleCancelEdit}>Annuler</button>
        </section>
      )}

      {/* Section de gestion des positions pour les groupes (premier niveau) */}
      <section className="positions-section">
        <h2>Gérer la position (par groupe de premier niveau)</h2>
        <ul>
          {topLevelGroups.map(cat => (
            <li key={cat.id}>
              <span>{cat.full_path.split('/')[0]}</span>
              <input
                type="number"
                value={positions[cat.id] || 0}
                onChange={(e) => handlePositionChange(cat.id, e.target.value)}
              />
            </li>
          ))}
        </ul>
        <button type="button" onClick={handleSavePositions}>
          Sauvegarder les positions
        </button>
      </section>
    </div>
  );
}

export default CategoriesPage;
