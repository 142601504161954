import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import ProductsPage from './pages/ProductsPage';
import RentalsPage from './pages/RentalsPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import NavBar from './components/NavBar';
import CategoriesPage from './pages/CategoriesPage';
import ClientsPage from './pages/ClientsPage';
import CreateQuotePage from './pages/CreateQuotePage';

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Charger l'utilisateur depuis le localStorage lors du montage du composant
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setIsLoading(false);
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const ProtectedRoute = ({ children }) => {
    if (isLoading) {
      return <div>Chargement...</div>;
    }
    return user ? children : <Navigate to="/login" />;
  };

  return (
    <Router>
      {user && <NavBar onLogout={handleLogout} />}
      <Routes>
        {/* Redirection à la racine */}
        <Route
          path="/"
          element={isLoading ? <div>Chargement...</div> : user ? <Navigate to="/home" /> : <Navigate to="/login" />}
        />
        <Route
          path="/login"
          element={!user ? <LoginPage onLogin={handleLogin} /> : <Navigate to="/home" />}
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <HomePage user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/products"
          element={
            <ProtectedRoute>
              <ProductsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rentals"
          element={
            <ProtectedRoute>
              <RentalsPage />
            </ProtectedRoute>
          }
        />
          <Route
          path="/categories"
          element={
            <ProtectedRoute>
              <CategoriesPage />
            </ProtectedRoute>
          }
        />

<Route
          path="/clients"
          element={
            <ProtectedRoute>
              <ClientsPage />
            </ProtectedRoute>
          }
        />
        <Route
  path="/create-quote"
  element={
    <ProtectedRoute>
      <CreateQuotePage />
    </ProtectedRoute>
  }
/>
        {/* <Route
          path="/register"
          element={
            <ProtectedRoute>
              <RegisterPage />
            </ProtectedRoute>
          }
        /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

const NotFound = () => {
  const location = useLocation();
  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      <h2>404 - Page Non Trouvée</h2>
      <p>Aucune correspondance pour <code>{location.pathname}</code></p>
    </div>
  );
};

export default App;
