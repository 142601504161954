import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CriticalDatesCalendar.css';
import { FaExclamationTriangle, FaCheckCircle, FaTimes } from 'react-icons/fa';

function CriticalDatesCalendar({ productId }) {
  const [availability, setAvailability] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [remainingStock, setRemainingStock] = useState(null);
  const [totalStock, setTotalStock] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://backend.ouest-pro-event.fr/admin/products/${productId}/availability-calendar`)
      .then(res => {
        if (!res.ok) throw new Error('Erreur lors du chargement des données');
        return res.json();
      })
      .then(data => {
        setAvailability(data.availability || {});
        setTotalStock(data.stock || 0);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        setError(err.message);
        setIsLoading(false);
      });
  }, [productId]);

  const tileClassName = ({ date }) => {
    const dateStr = date.toISOString().slice(0, 10);
    const stockLeft = availability[dateStr] !== undefined ? availability[dateStr] : totalStock;
    
    if (stockLeft <= 0) {
      return 'critical-date-red';
    } else if (stockLeft <= 50) {
      return 'critical-date-orange';
    } else {
      return 'normal-stock';
    }
  };

  const tileContent = ({ date }) => {
    const dateStr = date.toISOString().slice(0, 10);
    const stockLeft = availability[dateStr] !== undefined ? availability[dateStr] : totalStock;
    
    if (stockLeft <= 0) {
      return <div className="stock-indicator empty"></div>;
    } else if (stockLeft <= 50) {
      return <div className="stock-indicator low"></div>;
    } else {
      return <div className="stock-indicator available"></div>;
    }
  };

  const onDateClick = date => {
    const dateStr = date.toISOString().slice(0, 10);
    const stockLeft = availability[dateStr] !== undefined ? availability[dateStr] : totalStock;
    setSelectedDate(dateStr);
    setRemainingStock(stockLeft);
  };

  const getStockStatus = () => {
    if (remainingStock <= 0) {
      return <span className="stock-status no-stock"><FaTimes /> Rupture de stock</span>;
    } else if (remainingStock <= 50) {
      return <span className="stock-status low-stock"><FaExclamationTriangle /> Stock limité</span>;
    } else {
      return <span className="stock-status in-stock"><FaCheckCircle /> Stock disponible</span>;
    }
  };

  if (isLoading) {
    return <div className="loading-container">Chargement du calendrier...</div>;
  }

  if (error) {
    return <div className="error-container">Erreur: {error}</div>;
  }

  return (
    <div className="stock-calendar-container">
      <h3 className="calendar-title">Disponibilité du produit</h3>
      
      <div className="calendar-legend">
        <div className="legend-item">
          <span className="legend-color available"></span>
          <span>Disponible</span>
        </div>
        <div className="legend-item">
          <span className="legend-color low"></span>
          <span>Stock limité</span>
        </div>
        <div className="legend-item">
          <span className="legend-color empty"></span>
          <span>Rupture de stock</span>
        </div>
      </div>
      
      <Calendar
        onClickDay={onDateClick}
        tileClassName={tileClassName}
        tileContent={tileContent}
        className="stock-calendar"
      />
      
      {selectedDate && (
        <div className="stock-info-card">
          <h4>Informations de stock</h4>
          <p className="selected-date">Date : <strong>{selectedDate}</strong></p>
          <p className="stock-quantity">Quantité disponible : <strong>{remainingStock}</strong></p>
          {getStockStatus()}
        </div>
      )}
    </div>
  );
}

export default CriticalDatesCalendar;