// pages/CreateQuotePage.js
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import './CreateQuotePage.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import { fr } from 'date-fns/locale';
import Select from 'react-select';

const companyLogo = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
registerLocale('fr', fr);

function CreateQuotePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const clientInfo = location.state?.clientInfo;
  
  // Référence pour la partie "aperçu" qui sera convertie en PDF
  const quoteRef = useRef(null);

  // État pour l'aperçu du devis
  const [selectedProductPreview, setSelectedProductPreview] = useState(null);
  
  // État pour gérer la disponibilité globale des produits
  const [productsAvailability, setProductsAvailability] = useState({});

  // État pour l’option de lavage (20 €)
  const [washingOption, setWashingOption] = useState(false);
  
  // État pour gérer la date sur le DatePicker
  const [calendarDate, setCalendarDate] = useState(new Date());
  
  // Redirection si aucun client n’est reçu
  useEffect(() => {
    if (!clientInfo) {
      navigate('/clients');
    }
  }, [clientInfo, navigate]);
  
  // Pré-remplir le formulaire avec les infos client
  const [formData, setFormData] = useState({
    firstName: clientInfo?.first_name || '',
    lastName: clientInfo?.last_name || '',
    email: clientInfo?.email || '',
    phone: clientInfo?.phone || '',
    startDate: '',
    endDate: '',
    deliveryMethod: 'pickup',
    deliveryAddress: {
      street: '',
      zipCode: '',
      city: ''
    }
  });

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [quoteData, setQuoteData] = useState(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  
  // État pour la distance de livraison et le prix de livraison
  const [deliveryDistance, setDeliveryDistance] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  // Méthode de paiement
  const [paymentMethod, setPaymentMethod] = useState('online');
  
  // Informations de l'entreprise
  const companyInfo = {
    name: "Ouest Pro Event",
    address: "4 Rue de L'argoat",
    zipCode: "22200",
    city: "Saint-Agathon",
    phone: "06.76.98.99.89",
    email: "contact@ouest-proevent.fr",
    siret: "123 456 789 00012",
    tvaRate: 20
  };

  // Fonctions de navigation sur le calendrier
  const nextMonth = () => {
    const nextDate = new Date(calendarDate);
    nextDate.setMonth(nextDate.getMonth() + 1);
    setCalendarDate(nextDate);
  };
  const prevMonth = () => {
    const prevDate = new Date(calendarDate);
    prevDate.setMonth(prevDate.getMonth() - 1);
    setCalendarDate(prevDate);
  };

  // Récupération des produits depuis le backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://backend.ouest-pro-event.fr/admin/products');
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des produits');
        }
        const data = await response.json();
        setProducts(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur:", error);
        setIsLoading(false);
      }
    };
    fetchProducts();
  }, []);

  // Gestion du changement de dates
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setFormData({
      ...formData,
      startDate: start,
      endDate: end
    });
  };

  // Calcul du nombre de jours sélectionnés
  const calculateDays = () => {
    if (!formData.startDate || !formData.endDate) return 0;
    const diffTime = Math.abs(formData.endDate - formData.startDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 inclut le dernier jour
  };

  // Gestion des champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name.startsWith('delivery.')) {
      // Mise à jour des champs de livraison (rue, code postal, ville)
      const field = name.split('.')[1];
      setFormData({
        ...formData,
        deliveryAddress: {
          ...formData.deliveryAddress,
          [field]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  // Gestion distance / prix livraison
  const handleDeliveryDistanceChange = (e) => {
    const distance = parseFloat(e.target.value);
    if (!isNaN(distance)) {
      setDeliveryDistance(distance);
    }
  };
  const handleDeliveryPriceChange = (e) => {
    const price = parseFloat(e.target.value);
    if (!isNaN(price)) {
      setDeliveryPrice(price);
    }
  };

  // Ajouter un produit à la liste sélectionnée
  const handleAddProduct = (product) => {
    if (!product) return;
    // Vérifier s'il est déjà sélectionné
    const existingProduct = selectedProducts.find(p => p.id === product.id);
    if (existingProduct) {
      handleQuantityChange(existingProduct.id, existingProduct.quantity + 1);
    } else {
      setSelectedProducts([
        ...selectedProducts,
        {
          id: product.id,
          name: product.name,
          dailyPrice: product.dailyPrice,
          quantity: 1,
          maxStock: product.stock
        }
      ]);
    }
  };

  // Gérer la quantité
  const handleQuantityChange = (productId, quantity) => {
    const product = selectedProducts.find(p => p.id === productId);
    if (!product) return;
    
    // Empêcher de dépasser le stock
    if (quantity > product.maxStock) {
      alert(`La quantité maximale disponible pour ce produit est ${product.maxStock}`);
      quantity = product.maxStock;
    }
    if (quantity < 0) quantity = 0;
    
    const updatedProducts = selectedProducts
      .map(p => p.id === productId ? { ...p, quantity } : p)
      .filter(p => p.quantity > 0);
    
    setSelectedProducts(updatedProducts);
  };

  // Supprimer un produit sélectionné
  const handleRemoveProduct = (productId) => {
    setSelectedProducts(selectedProducts.filter(p => p.id !== productId));
  };

  // *** GÉNÉRATION DE L'APERÇU DU DEVIS ***
  const generateQuotePreview = () => {
    // Vérifications
    if (!formData.startDate || !formData.endDate) {
      alert("Veuillez remplir les dates de début et de fin");
      return;
    }
    if (selectedProducts.length === 0) {
      alert("Veuillez sélectionner au moins un produit");
      return;
    }
    if (formData.deliveryMethod === 'delivery') {
      const { street, zipCode, city } = formData.deliveryAddress;
      if (!street || !zipCode || !city) {
        alert("Veuillez compléter l'adresse de livraison");
        return;
      }
    }
    
    const days = calculateDays();
    // Calcul du prix des produits
    const subtotal = selectedProducts.reduce((sum, product) =>
      sum + (days * product.quantity * product.dailyPrice), 0
    );

    // ** AJOUT DU FORFAIT LAVAGE ** 
    const washingCost = washingOption ? 20 : 0;

    // total HT = produits + livraison + lavage
    const totalHT = subtotal + deliveryPrice + washingCost;
    const tvaAmount = totalHT * (companyInfo.tvaRate / 100);
    const totalTTC = totalHT + tvaAmount;

    // numéro de devis temporaire
    const quoteNumber = `D-TEMP-${new Date().getTime().toString().slice(-6)}`;

    // Préparer l'objet qu'on va afficher en aperçu
    const preparedQuoteData = {
      quoteNumber,
      clientName: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      phone: formData.phone,
      deliveryMethod: formData.deliveryMethod,
      deliveryAddress: formData.deliveryMethod === 'delivery' ? formData.deliveryAddress : null,
      startDate: formData.startDate,
      endDate: formData.endDate,
      items: selectedProducts.map(product => ({
        product_id: product.id,
        product_name: product.name,
        quantity: product.quantity,
        daily_price: product.dailyPrice,
        subtotal: days * product.quantity * product.dailyPrice
      })),
      days,
      subtotal,
      deliveryPrice,
      deliveryDistance,
      washingCost,      // <-- pour l’afficher dans l’aperçu
      tvaAmount,
      totalPrice: totalTTC,
      paymentMethod
    };

    setQuoteData(preparedQuoteData);
    setIsPreviewMode(true);
  };

  // Formatage de date pour l’affichage
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR');
  };

  // Convertir une image en base64 (pour la génération PDF)
  const getBase64FromImageUrl = async (url) => {
    try {
      const absoluteUrl = url.startsWith('http') ? url : window.location.origin + url;
      const response = await fetch(absoluteUrl, {
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const blob = await response.blob();
      
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Erreur lors de la conversion de l'image en base64:", error);
      return process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
    }
  };

  // Création et envoi du devis (PDF + email)
  const handleCreateQuote = async () => {
    try {
      // Formatage date pour l'API
      const formatDateForAPI = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      // Étape 1: création de la location
      const rentalData = {
        account_id: clientInfo.id,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        startDate: formatDateForAPI(formData.startDate),
        endDate: formatDateForAPI(formData.endDate),
        delivery_method: formData.deliveryMethod,
        payment_method: quoteData.paymentMethod,
        washing_option: washingOption, // <-- Important : on envoie l’option de lavage
        delivery_address:
          formData.deliveryMethod === 'delivery'
            ? {
                street: formData.deliveryAddress.street,
                zipCode: formData.deliveryAddress.zipCode,
                city: formData.deliveryAddress.city
              }
            : undefined,
        items: quoteData.items.map(item => ({
          product_id: item.product_id,
          quantity: item.quantity,
          daily_price: item.daily_price
        }))
      };
      
      const rentalResponse = await fetch('https://backend.ouest-pro-event.fr/rentals', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(rentalData)
      });
      if (!rentalResponse.ok) {
        const errorData = await rentalResponse.json();
        throw new Error(errorData.error || 'Erreur lors de la création de la location');
      }
      
      const rentalResult = await rentalResponse.json();
      const rentalId = rentalResult.rental_id;

      // Étape 2: mise à jour du prix de livraison + lavage si besoin
      if (formData.deliveryMethod === 'delivery' && (deliveryDistance > 0 || deliveryPrice > 0)) {
        const updateResponse = await fetch(
          `https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/update_quote`,
          {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              items: quoteData.items,
              total_price: quoteData.totalPrice,
              delivery_distance: deliveryDistance,
              delivery_price: deliveryPrice,
              washing_option: washingOption // <-- Répéter pour être sûr que le back l’ait bien
            })
          }
        );
        if (!updateResponse.ok) {
          console.error("Erreur lors de la mise à jour des informations de livraison et lavage");
        }
      }

      // Étape 3: Générer et envoyer le PDF (devis par email)
      // Récupération de tous les styles
      let stylesHTML = '';
      const styleSheets = document.styleSheets;
      for (let i = 0; i < styleSheets.length; i++) {
        const sheet = styleSheets[i];
        try {
          const rules = sheet.cssRules || sheet.rules;
          for (let j = 0; j < rules.length; j++) {
            stylesHTML += rules[j].cssText;
          }
        } catch (e) {
          console.log('Erreur lors de l\'accès aux règles CSS:', e);
        }
      }

      // Cloner l'élément du DOM pour le PDF
      const quoteElement = quoteRef.current.cloneNode(true);
      
      // Traitement du logo
      const logoImg = quoteElement.querySelector('.company-logo');
      if (logoImg) {
        try {
          const logoUrl = process.env.PUBLIC_URL + '/Logo_horizontal_fondnoir-_1_.webp';
          const base64Logo = await getBase64FromImageUrl(logoUrl);
          if (base64Logo) {
            logoImg.src = base64Logo;
          }
        } catch (error) {
          console.error("Erreur lors du chargement du logo:", error);
        }
      }
      
      // Remplacer toutes les autres images par base64
      const images = quoteElement.querySelectorAll('img:not(.company-logo)');
      for (let i = 0; i < images.length; i++) {
        const img = images[i];
        const imgSrc = img.src;
        const base64 = await getBase64FromImageUrl(imgSrc);
        if (base64) {
          img.src = base64;
        }
      }
      
      // Contenu HTML final
      const quoteHtmlContent = quoteElement.innerHTML;
      const completeHtml = `
        <!DOCTYPE html>
        <html>
        <head>
          <meta charset="UTF-8">
          <title>Devis ${quoteData.quoteNumber}</title>
          <style>
            ${stylesHTML}
            @page { size: A4; margin: 2cm; }
            body { font-family: Arial, sans-serif; }
            img { max-width: 100%; }
            .quote-company-info .company-logo {
              max-width: 200px;
              height: auto;
              margin-right: 20px;
              object-fit: contain;
              display: block;
            }
          </style>
        </head>
        <body>
          <div class="quote-preview">${quoteHtmlContent}</div>
        </body>
        </html>
      `;

      const sendResponse = await fetch(
        `https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/generate_and_send_quote`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            html: completeHtml,
            delivery_distance: deliveryDistance,
            delivery_price: deliveryPrice,
            quote_data: quoteData,
            payment_method: quoteData.paymentMethod,
            // On envoie aussi l’option
            washing_option: washingOption 
          })
        }
      );
      if (!sendResponse.ok) {
        const errorData = await sendResponse.json();
        console.error("Erreur lors de l'envoi du devis:", errorData.error);
        alert(`Devis créé avec succès, mais erreur lors de l'envoi par email: ${errorData.error}`);
      } else {
        alert(`Devis créé et envoyé avec succès (ID: ${rentalId})`);
      }

      // Redirection finale
      navigate('/rentals');
    } catch (error) {
      console.error("Erreur lors de la création du devis:", error);
      alert(`Erreur: ${error.message}`);
    }
  };

  // Bouton retour à l’édition
  const handleBackToForm = () => {
    setIsPreviewMode(false);
  };

  // Vérification de la dispo pour tous les produits
  const checkAllProductsAvailability = async () => {
    if (!formData.startDate || !formData.endDate) return;
    const availabilityData = {};
    
    for (const product of products) {
      try {
        const response = await fetch(`https://backend.ouest-pro-event.fr/availability/${product.id}`);
        if (!response.ok) continue;
        
        const data = await response.json();
        const availability = data.availability;
        
        let currentDate = new Date(formData.startDate);
        const endDateObj = new Date(formData.endDate);
        let isAvailable = true;
        let minAvailable = availability.stock;
        
        while (currentDate <= endDateObj) {
          const dateStr = currentDate.toISOString().split('T')[0];
          const rentedQuantity = availability.rented[dateStr] || 0;
          const availableQuantity = availability.stock - rentedQuantity;
          
          if (availableQuantity < 1) {
            isAvailable = false;
          }
          if (availableQuantity < minAvailable) {
            minAvailable = availableQuantity;
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        
        availabilityData[product.id] = {
          isAvailable,
          stockAvailable: minAvailable
        };
      } catch (error) {
        console.error(`Erreur de disponibilité pour le produit ${product.id}:`, error);
      }
    }
    setProductsAvailability(availabilityData);
  };
  
  useEffect(() => {
    if (formData.startDate && formData.endDate) {
      checkAllProductsAvailability();
    }
  }, [formData.startDate, formData.endDate]);

  // Mise en forme pour le composant Select
  const productOptions = products.map(product => ({
    value: product.id,
    label: product.name,
    data: product
  }));

  if (loading) {
    return <div className="loading">Chargement des produits...</div>;
  }

  return (
    <div className="create-quote-page">
      <h1>Créer un devis pour {formData.firstName} {formData.lastName}</h1>

      {!isPreviewMode ? (
        <div className="quote-form-container">
          {/* ------------------------------------------
              SECTION INFOS CLIENT (modifiables)
          ------------------------------------------ */}
          <div className="form-section client-info-editable">
            <h2>Informations client</h2>
            <div className="client-details-form">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="firstName">Prénom</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Nom</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Téléphone</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                    placeholder="Ex: 0612345678"
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* ------------------------------------------
              SECTION DATES DE LOCATION
          ------------------------------------------ */}
          <div className="form-section">
            <h2>Dates de location</h2>
            <div className="date-picker-container">
              <label>Sélectionnez les dates de début et de fin</label>
              
              <div className="calendar-with-navigation">
                <button
                  className="calendar-nav-button prev-button"
                  onClick={prevMonth}
                  type="button"
                  aria-label="Mois précédent"
                >
                  <i className="fas fa-chevron-left"></i>
                </button>

                <div className="calendar-wrapper">
                  <DatePicker
                    selected={formData.startDate}
                    onChange={handleDateChange}
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    selectsRange
                    inline
                    monthsShown={1}
                    minDate={new Date()}
                    calendarClassName="custom-calendar"
                    openToDate={calendarDate}
                    showMonthYearPicker={false}
                    showFullMonthYearPicker={false}
                    disabledKeyboardNavigation
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    renderCustomHeader={({ date }) => (
                      <div className="custom-calendar-header">
                        <span className="month-year-label">
                          {date.toLocaleString('fr-FR', {
                            month: 'long',
                            year: 'numeric',
                          })}
                        </span>
                      </div>
                    )}
                  />
                </div>

                <button
                  className="calendar-nav-button next-button"
                  onClick={nextMonth}
                  type="button"
                  aria-label="Mois suivant"
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>

              <div className="selected-dates-summary">
                <div className="date-info">
                  <div className="date-label">Date de début:</div>
                  <div className="date-value">
                    {formData.startDate
                      ? formData.startDate.toLocaleDateString('fr-FR')
                      : 'Non sélectionnée'}
                  </div>
                </div>
                <div className="date-info">
                  <div className="date-label">Date de fin:</div>
                  <div className="date-value">
                    {formData.endDate
                      ? formData.endDate.toLocaleDateString('fr-FR')
                      : 'Non sélectionnée'}
                  </div>
                </div>
                <div className="date-info highlight">
                  <div className="date-label">Durée:</div>
                  <div className="date-value">{calculateDays()} jour(s)</div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------------------------------------
              SECTION MODE DE LIVRAISON
          ------------------------------------------ */}
          <div className="form-section">
            <h2>Mode de livraison</h2>
            <div className="delivery-method-selector">
              <div
                className={`delivery-option ${
                  formData.deliveryMethod === 'pickup' ? 'selected' : ''
                }`}
                onClick={() =>
                  setFormData({ ...formData, deliveryMethod: 'pickup' })
                }
              >
                <div className="icon-container">
                  <i className="fas fa-store"></i>
                </div>
                <h3>Récupération sur place</h3>
                <p>Venez chercher votre matériel à notre entrepôt</p>
              </div>
              
              <div
                className={`delivery-option ${
                  formData.deliveryMethod === 'delivery' ? 'selected' : ''
                }`}
                onClick={() =>
                  setFormData({ ...formData, deliveryMethod: 'delivery' })
                }
              >
                <div className="icon-container">
                  <i className="fas fa-truck"></i>
                </div>
                <h3>Livraison</h3>
                <p>Nous livrons le matériel directement à votre adresse</p>
              </div>
            </div>

            {formData.deliveryMethod === 'delivery' && (
              <div className="delivery-address-form">
                <h3>Adresse de livraison</h3>
                <div className="form-group">
                  <label htmlFor="delivery.street">Rue *</label>
                  <input
                    type="text"
                    id="delivery.street"
                    name="delivery.street"
                    value={formData.deliveryAddress.street}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="delivery.zipCode">Code postal *</label>
                    <input
                      type="text"
                      id="delivery.zipCode"
                      name="delivery.zipCode"
                      value={formData.deliveryAddress.zipCode}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="delivery.city">Ville *</label>
                    <input
                      type="text"
                      id="delivery.city"
                      name="delivery.city"
                      value={formData.deliveryAddress.city}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="deliveryDistance">Distance (km)</label>
                    <input
                      type="number"
                      id="deliveryDistance"
                      value={deliveryDistance}
                      onChange={handleDeliveryDistanceChange}
                      min="0"
                      step="1"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="deliveryPrice">Prix de livraison (€)</label>
                    <input
                      type="number"
                      id="deliveryPrice"
                      value={deliveryPrice}
                      onChange={handleDeliveryPriceChange}
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* ------------------------------------------
              SECTION PRODUITS
          ------------------------------------------ */}
          <div className="form-section">
            <h2>Produits</h2>
            <div className="product-selection">
              <div className="product-dropdown-container">
                <Select
                  className="product-dropdown"
                  options={productOptions}
                  placeholder="Sélectionner un produit"
                  isClearable
                  formatOptionLabel={(option) => {
                    const productId = option.value;
                    const isUnavailable =
                      productsAvailability[productId] &&
                      !productsAvailability[productId].isAvailable;
                    return (
                      <div
                        className={`product-option ${
                          isUnavailable ? 'unavailable-product' : ''
                        }`}
                      >
                        {option.data.photos && option.data.photos.length > 0 ? (
                          <img
                            src={
                              "https://backend.ouest-pro-event.fr//" +
                              option.data.photos[0].url
                            }
                            alt={option.label}
                            className="product-option-image"
                          />
                        ) : (
                          <div className="product-option-placeholder"></div>
                        )}
                        <div className="product-option-details">
                          <div className="product-option-name">
                            {option.label}
                            {isUnavailable && (
                              <span className="stock-warning">
                                {' '}
                                (Non disponible pour ces dates)
                              </span>
                            )}
                          </div>
                          <div className="product-option-price">
                            {option.data.dailyPrice}€/jour - Stock:{' '}
                            {productsAvailability[productId]
                              ? `${
                                  productsAvailability[productId].stockAvailable
                                } disponible(s)`
                              : option.data.stock}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  onChange={(selected) => {
                    if (selected) {
                      const productId = selected.value;
                      const isUnavailable =
                        productsAvailability[productId] &&
                        !productsAvailability[productId].isAvailable;
                      if (isUnavailable) {
                        alert("Ce produit n'est pas disponible pour ces dates.");
                        return;
                      }
                      handleAddProduct(selected.data);
                    }
                  }}
                />
              </div>

              {selectedProducts.length > 0 ? (
                <table className="selected-products-table">
                  <thead>
                    <tr>
                      <th>Produit</th>
                      <th>Prix unitaire/jour</th>
                      <th>Quantité</th>
                      <th>Total HT</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProducts.map((product) => (
                      <tr key={product.id}>
                        <td>{product.name}</td>
                        <td className="text-right">
                          {product.dailyPrice.toFixed(2)} €
                        </td>
                        <td className="quantity-cell">
                          <button
                            onClick={() =>
                              handleQuantityChange(
                                product.id,
                                product.quantity - 1
                              )
                            }
                            className="quantity-btn"
                          >
                            -
                          </button>
                          <input
                            type="number"
                            value={product.quantity}
                            onChange={(e) =>
                              handleQuantityChange(
                                product.id,
                                parseInt(e.target.value) || 0
                              )
                            }
                            min="1"
                            max={product.maxStock}
                          />
                          <button
                            onClick={() =>
                              handleQuantityChange(
                                product.id,
                                product.quantity + 1
                              )
                            }
                            className="quantity-btn"
                          >
                            +
                          </button>
                        </td>
                        <td className="text-right">
                          {(
                            product.quantity *
                            product.dailyPrice *
                            calculateDays()
                          ).toFixed(2)}{' '}
                          €
                        </td>
                        <td>
                          <button
                            className="remove-btn"
                            onClick={() => handleRemoveProduct(product.id)}
                          >
                            Supprimer
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3" className="text-right">
                        Sous-total HT
                      </td>
                      <td className="text-right">
                        {selectedProducts
                          .reduce(
                            (sum, product) =>
                              sum +
                              product.quantity *
                                product.dailyPrice *
                                calculateDays(),
                            0
                          )
                          .toFixed(2)}{' '}
                        €
                      </td>
                      <td></td>
                    </tr>
                    {formData.deliveryMethod === 'delivery' &&
                      deliveryPrice > 0 && (
                        <tr>
                          <td colSpan="3" className="text-right">
                            Livraison HT
                          </td>
                          <td className="text-right">
                            {deliveryPrice.toFixed(2)} €
                          </td>
                          <td></td>
                        </tr>
                      )}
                    {/* Ici on ne met pas encore le lavage car on l’ajoute dans l’aperçu final */}
                    <tr>
                      <td colSpan="3" className="text-right">
                        TVA ({companyInfo.tvaRate}%)
                      </td>
                      <td className="text-right">
                        {(
                          (selectedProducts.reduce(
                            (sum, product) =>
                              sum +
                              product.quantity *
                                product.dailyPrice *
                                calculateDays(),
                            0
                          ) +
                            deliveryPrice) *
                          (companyInfo.tvaRate / 100)
                        ).toFixed(2)}{' '}
                        €
                      </td>
                      <td></td>
                    </tr>
                    <tr className="total-row">
                      <td colSpan="3" className="text-right">
                        Total TTC
                      </td>
                      <td className="text-right">
                        {(
                          (selectedProducts.reduce(
                            (sum, product) =>
                              sum +
                              product.quantity *
                                product.dailyPrice *
                                calculateDays(),
                            0
                          ) +
                            deliveryPrice) *
                          (1 + companyInfo.tvaRate / 100)
                        ).toFixed(2)}{' '}
                        €
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              ) : (
                <div className="no-products-selected">
                  Aucun produit sélectionné
                </div>
              )}
            </div>
          </div>

          {/* ------------------------------------------
              SECTION OPTION LAVAGE
          ------------------------------------------ */}
          <div className="form-section">
            <h2>Options supplémentaires</h2>
            <div className="washing-option-toggle">
              <label>
                <input
                  type="checkbox"
                  checked={washingOption}
                  onChange={(e) => setWashingOption(e.target.checked)}
                />
                Option de lavage (20 €)
              </label>
            </div>
          </div>

          {/* ------------------------------------------
              SECTION MÉTHODE DE PAIEMENT
          ------------------------------------------ */}
          <div className="form-section">
            <h2>Méthode de paiement</h2>
            <div className="payment-method-selector">
              <div
                className={`payment-option ${
                  paymentMethod === 'online' ? 'selected' : ''
                }`}
                onClick={() => setPaymentMethod('online')}
              >
                <div className="icon-container">
                  <i className="fas fa-credit-card"></i>
                </div>
                <h3>Paiement en ligne</h3>
                <p>Payer par carte bancaire via notre service sécurisé</p>
              </div>
              
              <div
                className={`payment-option ${
                  paymentMethod === 'transfer' ? 'selected' : ''
                }`}
                onClick={() => setPaymentMethod('transfer')}
              >
                <div className="icon-container">
                  <i className="fas fa-university"></i>
                </div>
                <h3>Virement bancaire</h3>
                <p>Les coordonnées bancaires seront incluses dans le devis</p>
              </div>
            </div>
          </div>

          {/* ------------------------------------------
              BOUTON APERÇU
          ------------------------------------------ */}
          <div className="form-actions">
            <button
              type="button"
              className="preview-btn"
              onClick={generateQuotePreview}
            >
              Aperçu du devis
            </button>
          </div>
        </div>
      ) : (
        // --------------------------------------------
        // APERÇU DU DEVIS (MODE PREVIEW)
        // --------------------------------------------
        <div className="quote-preview-container">
          <div className="quote-actions">
            <button className="back-btn" onClick={handleBackToForm}>
              Retour au formulaire
            </button>
            <button className="create-btn" onClick={handleCreateQuote}>
              Créer et envoyer le devis
            </button>
          </div>
          
          <div ref={quoteRef} className="quote-preview">
            <div className="quote-header-section">
              <div className="quote-company-info">
                <img src={companyLogo} alt="Logo" className="company-logo" />
                <div className="company-details">
                  <h3>{companyInfo.name}</h3>
                  <p>{companyInfo.address}</p>
                  <p>{companyInfo.zipCode} {companyInfo.city}</p>
                  <p>Tél: {companyInfo.phone}</p>
                  <p>Email: {companyInfo.email}</p>
                  <p>SIRET: {companyInfo.siret}</p>
                </div>
              </div>
              
              <div className="quote-reference">
                <h2>DEVIS</h2>
                <p><strong>N°:</strong> {quoteData.quoteNumber}</p>
                <p><strong>Date:</strong> {new Date().toLocaleDateString('fr-FR')}</p>
                <p><strong>Validité:</strong> 30 jours</p>
              </div>
            </div>
            
            <div className="quote-client-info">
              <h3>Client</h3>
              <p><strong>Nom:</strong> {quoteData.clientName}</p>
              <p><strong>Email:</strong> {quoteData.email}</p>
              {quoteData.phone && <p><strong>Téléphone:</strong> {quoteData.phone}</p>}
              
              {quoteData.deliveryMethod === 'delivery' && quoteData.deliveryAddress && (
                <div>
                  <p><strong>Adresse de livraison:</strong></p>
                  <p>{quoteData.deliveryAddress.street}</p>
                  <p>{quoteData.deliveryAddress.zipCode} {quoteData.deliveryAddress.city}</p>
                  {quoteData.deliveryDistance > 0 && (
                    <p><strong>Distance:</strong> {quoteData.deliveryDistance} km</p>
                  )}
                </div>
              )}
            </div>
            
            <div className="quote-details">
              <h3>Détails du devis</h3>
              <p>
                <strong>Période de location:</strong>
                {' '}
                {formatDate(quoteData.startDate)} au {formatDate(quoteData.endDate)}
              </p>
              <p><strong>Durée:</strong> {quoteData.days} jour{quoteData.days > 1 ? 's' : ''}</p>
              
              <table className="quote-items-table">
                <thead>
                  <tr>
                    <th>Désignation</th>
                    <th>Quantité</th>
                    <th>Prix unitaire/jour</th>
                    <th>Total HT</th>
                    <th>TVA ({companyInfo.tvaRate}%)</th>
                    <th>Total TTC</th>
                  </tr>
                </thead>
                <tbody>
                  {quoteData.items.map((item, index) => {
                    const itemTvaAmount = item.subtotal * (companyInfo.tvaRate / 100);
                    const itemTotalTTC = item.subtotal + itemTvaAmount;
                    return (
                      <tr key={index}>
                        <td>{item.product_name}</td>
                        <td className="text-center">{item.quantity}</td>
                        <td className="text-right">{item.daily_price.toFixed(2)} €</td>
                        <td className="text-right">{item.subtotal.toFixed(2)} €</td>
                        <td className="text-right">{itemTvaAmount.toFixed(2)} €</td>
                        <td className="text-right">{itemTotalTTC.toFixed(2)} €</td>
                      </tr>
                    );
                  })}

                  {/* Frais de livraison, si applicable */}
                  {quoteData.deliveryMethod === 'delivery' && quoteData.deliveryPrice > 0 && (
                    <tr className="delivery-row">
                      <td>Frais de livraison aller-retour {quoteData.deliveryDistance > 0 ? `(${quoteData.deliveryDistance} km)` : ''}</td>
                      <td className="text-center">1</td>
                      <td className="text-right">{quoteData.deliveryPrice.toFixed(2)} €</td>
                      <td className="text-right">{quoteData.deliveryPrice.toFixed(2)} €</td>
                      <td className="text-right">
                        {(quoteData.deliveryPrice * companyInfo.tvaRate / 100).toFixed(2)} €
                      </td>
                      <td className="text-right">
                        {(quoteData.deliveryPrice * (1 + companyInfo.tvaRate / 100)).toFixed(2)} €
                      </td>
                    </tr>
                  )}

                  {/* Forfait lavage (20€) si sélectionné */}
                  {quoteData.washingCost > 0 && (
                    <tr className="washing-row">
                      <td>Forfait lavage</td>
                      <td className="text-center">1</td>
                      <td className="text-right">
                        {quoteData.washingCost.toFixed(2)} €
                      </td>
                      <td className="text-right">
                        {quoteData.washingCost.toFixed(2)} €
                      </td>
                      <td className="text-right">
                        {(
                          quoteData.washingCost * (companyInfo.tvaRate / 100)
                        ).toFixed(2)} €
                      </td>
                      <td className="text-right">
                        {(
                          quoteData.washingCost +
                          quoteData.washingCost * (companyInfo.tvaRate / 100)
                        ).toFixed(2)} €
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-right">Sous-total HT</td>
                    <td className="text-right">
                      {(quoteData.subtotal + quoteData.deliveryPrice + quoteData.washingCost).toFixed(2)} €
                    </td>
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-right">TVA ({companyInfo.tvaRate}%)</td>
                    <td colSpan="1"></td>
                    <td className="text-right">{quoteData.tvaAmount.toFixed(2)} €</td>
                    <td></td>
                  </tr>
                  <tr className="total-row">
                    <td colSpan="5" className="text-right">Total TTC</td>
                    <td className="text-right">{quoteData.totalPrice.toFixed(2)} €</td>
                  </tr>
                  <tr className="deposit-row">
                    <td colSpan="5" className="text-right">Acompte à payer (50%)</td>
                    <td className="text-right">
                      {(quoteData.totalPrice * 0.5).toFixed(2)} €
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* Conditions + infos paiement */}
            <div className="quote-terms">
              <h3>Conditions</h3>
              <p>Ce devis est valable 30 jours à compter de sa date d'émission.</p>
              <p>Un acompte de 50% du montant total est demandé pour confirmer la commande.</p>
              <p>Le solde devra être réglé à la livraison du matériel.</p>

              {quoteData && quoteData.paymentMethod === 'transfer' && (
                <div className="bank-details">
                  <h4>Coordonnées bancaires pour le virement</h4>
                  <p><strong>Titulaire :</strong>OUEST PRO EVENT</p>
                  <p><strong>IBAN :</strong> FR76 1558 9228 0300 0744 9264 909</p>
                  <p><strong>BIC :</strong>CMBRFR2BXXX</p>
                  <p><strong>Référence :</strong> Devis N°{quoteData.quoteNumber}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateQuotePage;
